<template>
    <div
        class="instructor-cover-bg--redesign"
        :class="{ 'no-margin': memberView }"
    >
        <section
            id="instructor-details"
            class="instructor-section instructor-cover--redesign"
            :class="{ 'edit-mode': editMode }"
        >
            <div class="instructor-container">
                <div
                    class="instructor-img"
                    :class="profilePictureBackgroundClass"
                >
                    <ProfilePicture
                        :t="t.profilePicture"
                        :profile-photo-url="instructorState.profile_photo_url"
                        background-class=""
                        :theme-color="themeColor"
                        :edit-mode="editMode"
                        :save-photo-action="savePhotoAction"
                        @photo:updated="(preview) => instructorState.profile_photo_url = preview"
                    />
                </div>
                <div class="instructor-cover">
                    <div
                        class="instructor-type-tags"
                    >
                        <div
                            v-for="(instructorTypeLabel, index) in instructorTypeLabels"
                            :key="instructorTypeLabel"
                        >
                            <tag
                                v-if="instructorTypeLabel !== ''"
                                :variety="instructorTypeTagVariety(index) + ' filled'"
                                :show-cancel="false"
                            >
                                {{ instructorTypeLabel }}
                            </tag>
                        </div>
                    </div>
                    <div class="instructor-details">
                        <h1 class="instructor-name instructor-name--redesign">
                            {{ instructorState.display_name }}
                            <PencilSvg
                                v-if="editMode"
                                class="edit-mode-icon"
                                @click="modals.name = !modals.name"
                            />
                            <InstructorNameModal
                                :t="t.instructorNameModal"
                                :instructor-name="instructorState.display_name"
                                :show-modal="modals.name"
                                :error="instructorState.error"
                                :handle-closed-modal="() => modals.name = !modals.name"
                                @update:name="handleSavedName"
                            />
                        </h1>
                    </div>
                    <div class="instructor-details-container">
                        <ul class="instructor-details-list instructor-details-list--redesign">
                            <li
                                v-if="instructorLocation"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                {{ instructorLocation }}
                            </li>
                            <li :class="[ isInterFont ? 'f-inter' : 'z-b4']">
                                {{ membershipTypeLabel }}
                            </li>
                        </ul>
                        <ul class="instructor-details-list instructor-details-list--redesign ratings">
                            <li
                                v-if="instructorRatingScore"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4', !editMode ? 'rating-mobile' : '' ]"
                            >
                                <StarSvg /> {{ instructorRatingScore }}
                            </li>
                            <li
                                v-if="instructorNumReviews"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                <a
                                    href="#instructor-reviews"
                                    :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                                >
                                    {{ instructorNumReviews }} {{ t.ratings }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="instructor-details-container-mobile">
                        <ul class="instructor-details-list instructor-details-list--redesign">
                            <li
                                v-if="instructorLocation"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                {{ instructorLocation }}
                            </li>
                        </ul>
                        <ul class="instructor-details-list instructor-details-list--redesign">
                            <li :class="[ isInterFont ? 'f-inter' : 'z-b4']">
                                {{ membershipTypeLabel }}
                            </li>
                        </ul>
                        <ul class="instructor-details-list mobile-list instructor-details-list--redesign rating-mobile">
                            <li
                                v-if="instructorRatingScore"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                <StarSvg /> {{ instructorRatingScore }}
                            </li>
                            <li
                                v-if="instructorNumReviews"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                <a href="#instructor-reviews">{{ instructorNumReviews }} {{ t.ratings }}</a>
                            </li>
                        </ul>
                    </div>
                    <HorizontalSeparator
                        v-if="!editMode"
                        class="section-divider"
                    />
                    <div
                        v-if="editMode"
                        class="instructor-share-button-mobile cta-container"
                    >
                        <ZButton
                            variety="love"
                            compact="desktop-only"
                            class="share-button"
                            @click.prevent="handleShareButtonClick"
                        >
                            <template #icon>
                                <IcExport />
                            </template>
                            {{ t.share }}
                        </ZButton>
                        <ZButton
                            variety="love"
                            compact="desktop-only"
                            class="share-button"
                            @click.prevent="modals.qr = true"
                        >
                            <template #icon>
                                <QRCodeSVG class="qr-icon" />
                            </template>
                        </ZButton>
                    </div>
                    <div class="fitness_types fitness_types--redesign">
                        <div
                            v-if="!editMode"
                            class="licenses-and-certs-column"
                        >
                            <div
                                class="licenses-and-certs-label headline"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                {{ t.licensesCertifications }}
                            </div>
                            <div>
                                <ul
                                    v-if="!Boolean(customizedLicensesText)"
                                    class="licenses-and-certs-list"
                                >
                                    <li
                                        v-for="license in defaultLicenses"
                                        :key="license.badge_slug"
                                        class="licenses-and-certs-list-item"
                                        :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                                    >
                                        {{ license.badge_name }}
                                    </li>
                                </ul>
                                <span
                                    v-else
                                    class="customized-licenses"
                                    :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                                >
                                    {{ customizedLicensesText }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="editMode"
                        class="instructor-details-editable"
                    >
                        <div class="editable-row">
                            <div class="row-label f-inter">
                                <span>
                                    {{ t.contact }}
                                </span>
                                <PencilSvg
                                    class="edit-mode-icon icons hide-desktop"
                                    @click.prevent="showContactModal = true"
                                />
                            </div>
                            <div class="row-actions f-inter">
                                <IcPost
                                    class="edit-mode-icon icons"
                                    :class="{'disabled': !instructorPhone}"
                                />
                                <Message
                                    class="edit-mode-icon icons message-icon"
                                    :class="{'disabled': !instructorEmail}"
                                />
                                <Whatsapp
                                    class="edit-mode-icon icons"
                                    :class="{'disabled': !instructorWhatsApp}"
                                />
                                <PencilSvg
                                    class="edit-mode-icon icons hide-mobile"
                                    @click.prevent="showContactModal = true"
                                />
                                <ContactModal
                                    :t="t"
                                    :show="showContactModal"
                                    :action="detailsInstructorAction"
                                    :email-value="instructorEmail"
                                    :whatsapp-value="instructorWhatsApp"
                                    :phone-value="instructorPhone"
                                    :dial-codes="dialCodes"
                                    @closed="showContactModal = false"
                                />
                            </div>
                        </div>
                        <div class="editable-row">
                            <div class="row-label f-inter">
                                <span>
                                    {{ t.myPageUrl }}
                                </span>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-desktop"
                                    :class="{disabled: instructorPageUrlDisableEditState}"
                                    @click="openMyPageURLModal()"
                                />
                            </div>
                            <div class="row-actions">
                                <span class="my-page-url-preview">{{ profileURL }}</span>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-mobile"
                                    :class="{disabled: instructorPageUrlDisableEditState}"
                                    @click="openMyPageURLModal()"
                                />
                            </div>
                            <MyPageURLModalHandler
                                :t="t.myPageUrlModal"
                                :shortname="instructorState.profile_shortname"
                                :show-modal="modals['my_page_url']"
                                :save-url-action="saveUrlAction"
                                :is-url-available-action="isUrlAvailableAction"
                                @cancel="modals.my_page_url = !modals.my_page_url"
                                @save="onSaveUrl($event)"
                            />
                        </div>
                        <div class="editable-row">
                            <div class="row-label f-inter">
                                <span class="social-label">
                                    {{ t.socialLabel }}
                                </span>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-desktop"
                                    @click="showSocialsModal = true"
                                />
                            </div>
                            <div class="row-actions">
                                <span
                                    v-for="network in networks"
                                    :key="network"
                                    class="social-item"
                                    :class="{
                                        'withValue': !!instructorSocials[network]
                                    }"
                                >
                                    <component :is="network + '-icon'" />
                                </span>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-mobile"
                                    @click="showSocialsModal = true"
                                />
                            </div>
                            <SocialModal
                                :t="t"
                                :action="detailsInstructorAction"
                                :social-links="instructorSocials"
                                :show="showSocialsModal"
                                @closed="showSocialsModal = false"
                            />
                        </div>
                        <div class="editable-row">
                            <div class="row-label f-inter">
                                <span>
                                    {{ t.websiteTitle }}
                                </span>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-desktop"
                                    @click="modals.website = !modals.website"
                                />
                            </div>
                            <div class="row-actions f-inter">
                                <a
                                    :href="instructorState.website?.page_url"
                                    :title="instructorState.website?.title"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {{ instructorState.website?.title || instructorState.website?.page_url }}
                                </a>
                                <PencilSvg
                                    class="edit-mode-icon action-icon hide-mobile"
                                    @click="modals.website = !modals.website"
                                />
                                <WebsiteModal
                                    :t="t.websiteModal"
                                    :website-title="instructorState.website?.title"
                                    :website-url="instructorState.website?.page_url"
                                    :show-modal="modals.website"
                                    :error="instructorState.error"
                                    :handle-closed-modal="() => modals.website = !modals.website"
                                    :website-title-scraper-action="websiteTitleScraperAction"
                                    @update:website="handleSavedWebsite"
                                />
                            </div>
                        </div>
                        <div
                            v-if="instructorTippingEnabled"
                            class="editable-row"
                        >
                            <div class="row-label f-inter">
                                <span>
                                    {{ t.tippingLabel }}
                                </span>
                                <a
                                    v-if="instructorTipEmail"
                                    :href="classManagementSettings"
                                >
                                    <PencilSvg
                                        class="edit-mode-icon action-icon hide-desktop"
                                    />
                                </a>
                            </div>
                            <div class="row-actions f-inter">
                                <span
                                    v-if="instructorTipEmail"
                                    class="toggle-label"
                                >
                                    {{ instructorTipping ? t.on : t.off }}
                                </span>
                                <a :href="classManagementSettings">
                                    <PencilSvg
                                        v-if="instructorTipEmail"
                                        class="edit-mode-icon action-icon hide-mobile"
                                    />
                                    <span v-else>{{ t.setUp }}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="social-section"
                        :class="{'hide-mobile': !hasSocialLinks && !Boolean(instructorWebsite?.page_url) }"
                    >
                        <HorizontalSeparator
                            v-if="!editMode"
                            class="section-divider hide-desktop"
                        />
                        <div
                            v-if="instructorWebsite?.page_url"
                            class="social"
                        >
                            <div
                                class="website-label social-section-subhead"
                                :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                            >
                                {{ t.websiteTitle }}
                            </div>
                            <div class="website-action">
                                <a
                                    :href="instructorWebsite.page_url"
                                    :title="instructorWebsite.title"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <span
                                        class="website-title"
                                        :class="[ isInterFont ? 'f-inter' : 'z-b4']"
                                    >
                                        {{ websiteTitle }}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <social
                            v-if="hasSocialLinks"
                            :t="t"
                            :social-links="instructorSocials"
                            size="mobile-large"
                        />
                    </div>
                    <HorizontalSeparator
                        v-if="!editMode"
                        class="section-divider"
                    />
                    <div
                        v-if="!editMode"
                        class="cta-container hide-mobile cta-container--redesign"
                    >
                        <div class="desktop-buttons-container">
                            <ZButton
                                :variety="isFollowing ? 'phoenix' : 'optimus'"
                                :mode="isFollowing ? 'dark' : 'light'"
                                class="follow-desktop follow-button"
                                :class="{'following': isFollowing}"
                                compact="desktop-only"
                                @click="followClick()"
                            >
                                <template #icon>
                                    <HeartFilledSvg
                                        v-if="isFollowing && showFollowIcon"
                                        class="following-icon"
                                    />
                                    <HeartSvg
                                        v-else-if="showFollowIcon"
                                        class="follow-icon"
                                    />
                                </template>
                                {{ isFollowing ? t.saved : t.save }}
                            </ZButton>
                        </div>
                        <contact-button-handler
                            :t="t"
                            :instructor-pid="instructorPid"
                            :has-phone="instructorHasPhone"
                            :has-email="instructorHasEmail"
                            :has-whatsapp="instructorHasWhatsApp"
                            :instructor-contact-action="instructorContactAction"
                            :instructor-display-name="instructorDisplayName"
                            :instructor-profile-photo-url="instructorProfilePhotoUrl"
                            :instructor-location="instructorLocation"
                            :requester-name="requesterName"
                            :requester-email="requesterEmail"
                            :recaptcha-key="recaptchaKey"
                            variety="punisher"
                            class="contact-button"
                            always-show-label
                            :show-icon="false"
                            compact="desktop-only"
                            @toast:message="$emit('toast:message', $event)"
                            @recaptcha:failure="$emit('toast:message', {type: 'danger', message: $event})"
                            @clicked:contact="mixpanelTrack"
                        />
                        <ZButton
                            v-if="instructorShareEnabled"
                            variety="punisher"
                            class="share-button"
                            compact="desktop-only"
                            @click.prevent="handleShareButtonClick"
                        >
                            <template #icon>
                                <IcExport />
                            </template>
                        </ZButton>
                        <ZButton
                            v-if="instructorTipping && instructorTippingEnabled"
                            variety="punisher"
                            compact="desktop-only"
                            class="tip-button"
                            @click.prevent="$emit('tip-modal:open')"
                        >
                            <a
                                @click.prevent="$emit('tip-modal:open')"
                            >
                                <GiftSvg />
                            </a>
                        </ZButton>
                    </div>
                    <div
                        v-if="editMode"
                        class="instructor-share-button hide-mobile"
                    >
                        <ZButton
                            v-if="editMode"
                            variety="love"
                            compact="desktop-only"
                            class="share-button"
                            @click.prevent="handleShareButtonClick"
                        >
                            <template #icon>
                                <IcExport />
                            </template>
                            {{ t.share }}
                        </ZButton>

                        <ZButton
                            variety="love"
                            compact="desktop-only"
                            class="share-button"
                            @click.prevent="modals.qr = true"
                        >
                            <template #icon>
                                <QRCodeSVG class="qr-icon" />
                            </template>
                        </ZButton>
                    </div>
                    <div class="instructor-details-actions">
                        <div class="cta-container-mobile">
                            <div
                                v-if="!editMode"
                            >
                                <ZButton
                                    :variety="isFollowing ? 'phoenix' : 'optimus'"
                                    :mode="isFollowing ? 'dark' : 'light'"
                                    class="follow-mobile follow-button"
                                    :class="{'following': isFollowing}"
                                    compact="desktop-only"
                                    @click="followClick()"
                                >
                                    <template #icon>
                                        <HeartFilledSvg
                                            v-if="isFollowing && showFollowIcon"
                                            class="following-icon"
                                        />
                                        <HeartSvg
                                            v-else-if="showFollowIcon"
                                            class="follow-icon"
                                        />
                                    </template>
                                    {{ isFollowing ? t.saved : t.save }}
                                </ZButton>
                            </div>
                        </div>
                        <div class="cta-container">
                            <div
                                v-if="!editMode"
                                class="buttons-container"
                            >
                                <contact-button-handler
                                    class="contact-button"
                                    :t="t"
                                    :instructor-pid="instructorPid"
                                    :has-phone="instructorHasPhone"
                                    :has-email="instructorHasEmail"
                                    :has-whatsapp="instructorHasWhatsApp"
                                    :always-show-label="true"
                                    :show-icon="false"
                                    :instructor-contact-action="instructorContactAction"
                                    :instructor-display-name="instructorDisplayName"
                                    :instructor-profile-photo-url="instructorProfilePhotoUrl"
                                    :instructor-location="instructorLocation"
                                    :requester-name="requesterName"
                                    :requester-email="requesterEmail"
                                    :recaptcha-key="recaptchaKey"
                                    variety="punisher"
                                    @toast:message="$emit('toast:message', $event)"
                                    @recaptcha:failure="$emit('toast:message', {type: 'danger', message: $event})"
                                    @clicked:contact="mixpanelTrack"
                                />
                                <ZButton
                                    v-if="instructorShareEnabled"
                                    variety="punisher"
                                    class="mobile-button share-button"
                                    compact="desktop-only"
                                    @click.prevent="handleShareButtonClick"
                                >
                                    <template #icon>
                                        <IcExport />
                                    </template>
                                </ZButton>
                                <ZButton
                                    v-if="instructorTipping && instructorTippingEnabled"
                                    variety="punisher"
                                    class="mobile-button tip-button"
                                    compact="desktop-only"
                                    @click.prevent="$emit('tip-modal:open')"
                                >
                                    <template #icon>
                                        <GiftSvg />
                                    </template>
                                </ZButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ShareModal
                :t="t"
                :share-type="QRCodeShareType.SHARE_INSTRUCTOR"
                :fb-app-id="fbAppId"
                :show="modals.share"
                :share-data="shareData"
                simplified
                @close="modals.share = false"
            />
            <QRCodeModal
                v-if="shareData?.qrSRC"
                :t="t"
                :show="modals.qr"
                :instructor-data="{
                    title: instructorState.display_name,
                    location: instructorState?.location,
                    membership_type: membershipTypeLabel,
                    rating_score: instructorState?.rating_score,
                    num_reviews: instructorState?.num_reviews,
                }"
                :qr-src="shareData?.qrSRC ?? ''"
                :share-qr-url="shareData.qrShareURL"
                :share-type="QRCodeShareType.SHARE_INSTRUCTOR"
                @close="modals.qr = false"
            />
        </section>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, reactive, ref, Ref } from 'vue'
import StarSvg from '@bx-icons/solid/bxs-star.svg'
import HeartFilledSvg from '@bx-icons/solid/bxs-heart.svg'
import HeartSvg from '@bx-icons/regular/bx-heart.svg'
import GiftSvg from '@bx-icons/regular/bx-gift.svg'
import PencilSvg from '@bx-icons/regular/bx-pencil.svg'
import WebSvg from '@icons/web.svg'
import { validateTranslations } from '@ts/Util/i18n'
import ZButton from '@components/Core/ZButton.vue'
import { csrfToken } from '@ts/Util/security'
import { post } from '@ts/Util/http'
import ContactButton from '@components/ContactButton/ContactButton.vue'
import ContactButtonHandler from '@components/ContactButton/ContactButtonHandler.vue'
import ProfilePicture, { BrandColors } from '@modules/InstructorPage/ProfilePicture.vue';
import Social from '@modules/InstructorPage/Social.vue'
import SocialModal from '@modules/InstructorPage/SocialModal.vue'
import { SocialLinksType } from '@components/SocialLinks/SocialLinks.vue';
import Tag from '@components/Tags/Tag.vue'
import InstructorNameModal from '@modules/InstructorPage/InstructorNameModal.vue'
import MyPageURLModalHandler from '@modules/InstructorPage/MyPageURLModalHandler.vue'
import WebsiteModal from '@modules/InstructorPage/WebsiteModal.vue'
import IcExport from '@bx-icons/regular/bx-export.svg'
import { interpolate } from '@ts/Util/text'
import ContactModal from '@modules/InstructorPage/ContactModal.vue'
import Message from '@bx-icons/regular/bx-envelope.svg'
import Whatsapp from '@bx-icons/logos/bxl-whatsapp.svg'
import IcPost from '@bx-icons/regular/bx-message-square-detail.svg'
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg'
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg'
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg'
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg'
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ShareModal from '@components/Sharing/ShareModal.vue'
import { QRCodeShareType } from '@components/Sharing/QRCodeModal.vue'
import { ShareableInfo } from '@ts/Sharing/sharing'
import { BadgeData } from '@modules/InstructorPage/ProfileBadges.vue'
import { mixpanelServiceTrack } from '@ts/Util/analytics';
import QRCodeSVG from "@bx-icons/regular/bx-qr.svg"
import QRCodeModal from '@components/Sharing/QRCodeModal.vue'
import {isMobile} from '@ts/Util/responsiveness'
import { DialCodeItems } from '@components/Core/DialCodeInput.vue'
import { theme, ThemeType } from '@ts/Util/theme'

interface Website {
    page_url: string,
    title: string,
}

export interface Instructor {
    pid: string,
    display_name: string,
    profile_photo_url?: string,
    location: string,
    about?: string,
    rating_score: number,
    instructor_type: string,
    socials: SocialLinksType,
    licenses?: string,
    membership: string,
    join_date: string,
    website: Website,
    instructor_tipping: boolean,
    tipping_enabled: boolean,
    tip_email?: string,
    num_reviews: number,
    followed: boolean,
    share_enabled: boolean,
    fitness_types: Array<string>,
    profile_shortname?: string,
    hasEmail: boolean,
    hasWhatsapp: boolean,
    hasPhone: boolean,
    email: string,
    whatsapp: string,
    phone: string
}

const translationDefaults = {
    websiteTitle: 'Website',
    leaveATipLabel: 'Send a thank you',
    leaveATipLinkTitle: 'Leave a tip',
    ratings: 'ratings',
    save: 'Save',
    following: 'Following',
    contactLabel: 'Contact',
    copiedToClipboard: 'Copied !',
    socialLabel: 'Social',
    tippingLabel: 'Tipping',
    setUp: 'Set up',
    on: 'On',
    off: 'Off',
    instructorNameModal: {
        instructions: 'Choose the name to display on your instructor page',
        title: 'Edit Name',
        yourName: 'Your name',
        requiredError: 'Name is required',
        save: 'Save',
        cancel: 'Cancel',
    },
    websiteModal: {
        instructions: 'Add a link to another webpage you want visitors to see.',
        title: 'Additional Webpage',
        pageLink: 'Page link',
        pageTitle: 'Page title',
        save: 'Save',
        cancel: 'Cancel',
    },
    profilePicture: {
        profileImageAlt: "Instructor picture",
        modal: {
            instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
            title: 'Instructor Photo',
            addPhoto: 'Add Photo',
            add: 'Add',
            deletePhoto: 'Delete Photo',
            delete: 'Delete',
            save: 'Save',
            cancel: 'Cancel',
            hint: 'We recommend a square 400x400px image, .jpg or .png',
        }
    },
    myPageUrl: 'My Page URL',
    myPageUrlModal: {
        title: 'My Page URL',
        shortname: 'Username',
        save: 'Save',
        cancel: 'Cancel',
        description: 'Enter your preferred username to customize the URL of this instructor page.',
        urlEditRequirementsHeader: 'Please be sure that your username:',
        urlEditRequirements1: 'begins and ends with a letter or number',
        urlEditRequirements2: 'is unique to you',
        baseUrl: 'https://www.zumba.com/p/{0}',
        errorCustomURL: 'The custom URL must be between 4-40 characters, and can only contain letters and numbers.',
        errorCustomURLTaken: "This custom URL is already taken please select another",
        confirmModal: {
            confirmNewUrl: 'Confirm new URL',
            back: 'Back',
            confirm: 'Confirm',
            baseUrl: 'https://www.zumba.com/p/{0}',
            uponConfirmation: 'Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more times.',
            uponConfirmationOne: 'Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more time.',
            uponConfirmationNoMore: 'Upon confirmation, your URL will be changed. You will not be able to edit this URL once it is changed.',
        }
    },
    share: 'share',
    licenseToTeach: 'License to teach',
    viewCredentials: 'View Credentials',
    additionalLicensesCertifications: 'Additional Licenses and Certifications',
    classesPosted: 'Classes Posted',
    licensesCertifications: 'Licenses & Certifications',
    profileImageAlt: 'Instructor picture',
    instructorSinceLabel: 'Instructor since',
    requiredFieldError: "Required Field.",
    emailError: "You must enter a valid email address to continue.",
}

export enum Brands {
    STRONG = 'strong',
    ZUMBA = 'zumba'
}

export default defineComponent({
    name: 'InstructorCover',
    components: {
        StarSvg,
        WebSvg,
        GiftSvg,
        ZButton,
        HeartFilledSvg,
        HeartSvg,
        ContactButton,
        ContactButtonHandler,
        ProfilePicture,
        SocialModal,
        Social,
        Tag,
        PencilSvg,
        InstructorNameModal,
        MyPageURLModalHandler,
        WebsiteModal,
        IcExport,
        ContactModal,
        Message,
        Whatsapp,
        IcPost,
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon,
        HorizontalSeparator,
        ShareModal,
        QRCodeSVG,
        QRCodeModal
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        brand: {
            type: String as PropType<Brands>,
            required: true,
        },
        followInstructorAction: {
            type: String,
            required: true
        },
        specialties: {
            type: Object as PropType<Record<string, string>>,
            default: () => {}
        },
        instructorDisplayName: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfilePhotoUrl: {
            type: String,
            required: false,
            default: ''
        },
        instructorProfileShortname: {
            type: String,
            required: false,
            default: ''
        },
        instructorLocation: {
            type: String,
            required: true
        },
        instructorJoinDate: {
            type: String,
            required: true
        },
        instructorRatingScore: {
            type: Number,
            required: true
        },
        instructorFollowed: {
            type: Boolean,
            required: true
        },
        instructorSocials: {
            type: Object as PropType<SocialLinksType>,
            required: true
        },
        instructorInstructorType: {
            type: String,
            required: true
        },
        instructorWebsite: {
            type: Object as PropType<Website>,
            required: false,
            default: () => ({ page_url: '', title: '' })
        },
        instructorTippingEnabled: {
            type: Boolean,
            required: true
        },
        instructorTipping: {
            type: Boolean,
            required: true
        },
        instructorTipEmail: {
            type: String,
            default: ''
        },
        instructorShareEnabled: {
            type: Boolean,
            required: true
        },
        instructorHasEmail: {
            type: Boolean,
            required: true
        },
        instructorHasWhatsApp: {
            type: Boolean,
            required: true
        },
        instructorHasPhone: {
            type: Boolean,
            required: true
        },
        instructorEmail: {
            type: String,
            required: true
        },
        instructorWhatsApp: {
            type: String,
            required: true
        },
        instructorPhone: {
            type: String,
            required: true
        },
        instructorNumReviews: {
            type: Number,
            required: true
        },
        detailsInstructorAction: {
            type: String,
            default: () => '',
        },
        showFollowIcon: {
            type: Boolean,
            required: true
        },
        savePhotoAction: {
            type: String,
            required: false,
            default: ''
        },
        websiteTitleScraperAction: {
            type: String,
            required: false,
            default: ''
        },
        instructorPageUrlDisableEdit: {
            type: Boolean,
            default: false,
        },
        classManagementSettings: {
            type: String,
            required: true,
        },
        saveUrlAction: {
            type: String,
            required: true,
            default: ''
        },
        isUrlAvailableAction: {
            type: String,
            required: true,
            default: ''
        },
        userPid: {
            type: String,
            required: true,
        },
        shareData: {
            type: Object as PropType<ShareableInfo>,
            required: true
        },
        fbAppId: {
            type: String,
            required: true
        },
        isLoggedIn: {
            type: Boolean,
            required: true,
        },
        followInstructorLink: {
            type: String,
            required: true
        },
        followImmediately: {
            type: Boolean,
            default: false
        },
        instructorPid: {
            type: String,
            required: true
        },
        useAsyncContactButton: {
            type: Boolean,
            default: true
        },
        instructorContactAction: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            default: false
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        },
        defaultLicenses: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        customizedLicensesText: {
            type: String,
            required: true,
        },
        requesterName: {
            type: String,
            default: ''
        },
        requesterEmail: {
            type: String,
            default: ''
        },
        recaptchaKey: {
            type: String,
            default: ''
        },
        dialCodes: {
            type: Object as PropType<DialCodeItems>,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },
    emits: ['tip-modal:open', 'toast:message'],
    setup(props) {
        const instructorState = reactive({
            email: props.instructorEmail,
            display_name: props.instructorDisplayName,
            profile_shortname: props.instructorProfileShortname,
            profile_photo_url: props.instructorProfilePhotoUrl,
            website: props.instructorWebsite || '',
            location: props.instructorLocation,
            rating_score: props.instructorRatingScore,
            num_reviews: props.instructorNumReviews,
            error: ''
        })
        const instructorPageUrlDisableEditState = ref(props.instructorPageUrlDisableEdit);

        const instructorTypeTagVariety = (index) => {
            switch (index) {
            case 0:
                return 'featured'
            case 1:
                return 'information'
            case 2:
                return 'success'
            case 3:
                return 'warning'
            default:
                break;
            }
        }

        const profileURL = computed(() => {
            if (instructorState.profile_shortname) {
                return interpolate(
                    props.t.baseUrl,
                    [instructorState.profile_shortname ?? '']
                )
            }
            return interpolate(props.t.baseUrl, [instructorState.display_name.toLowerCase().replace(/\s/g, '-')]) + '/' + props.userPid;
        })

        const websiteTitle = computed(() => {
            const maxTitleLength = 25;
            const title = instructorState?.website?.title || instructorState?.website?.page_url;
            if (title.length > maxTitleLength) {
                return title.substring(0, maxTitleLength) + '...';
            }

            return title;
        });

        const modals = reactive({
            name: false,
            website: false,
            my_page_url: false,
            share: false,
            qr: false
        })

        const handleSavedName = async (e) => {
            let response = await post(props.detailsInstructorAction, csrfToken(), {
                display_name: e.value
            })

            if (response.ok) {
                instructorState.display_name = e.value
                modals.name = false
                return
            }

            throw Error('Error while saving instructor name')
        }

        const handleSavedWebsite = async (e) => {
            let response = await post(props.detailsInstructorAction, csrfToken(), {
                website_title: e.title,
                website_url: e.page_url
            })

            if (response.ok) {
                instructorState.website = {
                    page_url: e.page_url,
                    title: e.title,
                }
                modals.website = false
                return
            }

            throw Error('Error while saving instructor website')
        }

        const formatDate = (dateTime : string) => {
            if (!dateTime) {
                return null
            }
            const date : Date = new Date(dateTime)
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        }

        const membershipTypeLabel = computed(() => {
            const membershipType = props.t.instructorSinceLabel
            let joinDate = props.instructorJoinDate ? formatDate(props.instructorJoinDate) : null;

            return !membershipType ? '' : membershipType + " " +  joinDate;
        })

        const isInterFont = computed(() => (theme.value === ThemeType.Zumba));

        const isFollowing = ref(props.instructorFollowed)

        const follow = async (follow: boolean) => {
            const response = await post(props.followInstructorAction, csrfToken(), {
                instructorPID: props.instructorPid,
                following: follow,
            })

            if (response.ok) {
                isFollowing.value = follow
                return
            }

            throw Error('Error while following instructor')
        }
        const followClick = async () => {
            if (!props.isLoggedIn) {
                window.location.href = props.followInstructorLink
                return
            }
            follow(!isFollowing.value)
        }

        const themeColor = computed(() => {
            if (props.editMode) {
                return ''
            }

            return BrandColors[props.brand]
        })

        const instructorTypeLabels = computed(() => {
            if (props.editMode) {
                return ''
            }

            return props.instructorInstructorType.split(' ');
        })

        const profilePictureBackgroundClass = computed(() => {
            if (props.editMode) {
                return ''
            }

            const className = `profile-image-container-background-${props.brand}`
            return className;

        })
        const hasSocialLinks: Ref<boolean> = ref(
            !Object.values(props.instructorSocials).every(l => !l.length)
        )

        const networks: Ref<Array<string>> = ref([
            'facebook', 'instagram', 'spotify', 'tiktok', 'linkedin',
        ]);

        const showSocialsModal: Ref<boolean> = ref(false)
        const showContactModal: Ref<boolean> = ref(false)

        const openMyPageURLModal = () => {
            if (instructorPageUrlDisableEditState.value) {
                return
            }

            modals['my_page_url'] = !modals['my_page_url']
        }

        const onSaveUrl = ({shortname, requestsLeft}) => {
            instructorState.profile_shortname = shortname
            instructorPageUrlDisableEditState.value = requestsLeft === 0
            modals['my_page_url'] = !modals['my_page_url']
        }

        onMounted(() => {
            if (props.followImmediately && !isFollowing.value) {
                follow(true)
            }
        })

        const mixpanelReported = ref(false)

        const mixpanelTrack = () => {
            const referrer = document.referrer;
            // track redirects from the Clarity flow

            if (referrer.includes('/training/register') && !mixpanelReported.value) {
                mixpanelReported.value = true
                mixpanelServiceTrack(
                    'TrackContactedTrainingInstructor',
                    {
                        pageName: 'instructor_page'
                    }
                )
            }
        }

        const handleShareButtonClick = () => {
            const isMobileBrowser: boolean = isMobile()
            const hasNativeShare: boolean = !!navigator.share;

            if (isMobileBrowser && hasNativeShare) {
                // Trigger native share directly
                navigator.share({
                    url: props.shareData?.url
                }).catch(() => {});
            } else {
                // Open the modal
                modals.share = true;
            }
        };

        return {
            isInterFont,
            QRCodeShareType,
            showSocialsModal,
            hasSocialLinks,
            formatDate,
            membershipTypeLabel,
            isFollowing,
            followClick,
            modals,
            onSaveUrl,
            instructorPageUrlDisableEditState,
            instructorTypeTagVariety,
            openMyPageURLModal,
            handleSavedName,
            handleSavedWebsite,
            instructorState,
            themeColor,
            instructorTypeLabels,
            profilePictureBackgroundClass,
            Brands,
            networks,
            profileURL,
            showContactModal,
            websiteTitle,
            mixpanelTrack,
            mixpanelReported,
            handleShareButtonClick
        }
    }

});

</script>

<style scoped>
.buttons-container {
    display: none;
}
.social-section {
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-top: 1.5rem;
}

.social-section:deep(.social-links) {
    margin-top: 0.7875rem;
    margin-left: -0.1rem;
}

.social-section-subhead {
    font-weight: 400;
    line-height: 0.8875;
}

.tip-action {
    margin: 0.4875rem 0 0 -0.1rem;
}

.tip-text {
    position: relative;
    top: 0.2rem;
    left: .3rem;
}
.website-title {
    position: relative;
    top: 1rem;
}

.theme-strong .website-title,
.theme-strong .view-credentials-details {
    font-family: var(--strong-font-default);
}

.theme-zumba .website-title {
    font-size: 1rem;
}

.instructor-cover-bg--redesign {
    margin: 0 -1rem;
}

.instructor-cover-bg--redesign.no-margin {
    margin: 0;
}
.theme-strong .instructor-cover-bg--redesign {
  background: var(--strong-primary-gray);
}

.theme-zumba .instructor-cover-bg--redesign {
  background: var(--zumba-dark-green);
}
.instructor-container {
    display: grid;
    grid-template-columns: 474px 1fr;
    margin-bottom: -1.5rem;
}

.instructor-cover {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    max-width: 43.5rem;
}

.instructor-type-tags {
    display: flex;
    column-gap: .3125rem;
    margin-bottom: 1rem;
}

.instructor-type-tags :deep(.tag.featured) {
    background-color: var(--strong-primary-coral);
}
.theme-zumba .instructor-type-tags :deep(.tag.featured) {
    background-color: var(--zumba-plum);
}

.theme-zumba .instructor-type-tags :deep(.tag.information) {
    background-color: var(--zumba-white);
    color: var(--zumba-gray-800);
}

.instructor-type-tags :deep(.tag.success.filled),
.instructor-type-tags :deep(.tag.featured.filled) {
    color: var(--zumba-white);
}
.instructor-img {
    position: relative;
    left: -1rem;
}
.instructor-img :deep(svg) {
    max-width: 100%;
    max-height: 100%;
}
.instructor-details-container-mobile {
    display: none;
}
.instructor-details {
    display: grid;
    grid-template-columns: 2fr 2fr;
    margin: 0;
    text-align: inherit;
    min-height: 2.5rem;
}

.theme-strong .instructor-details {
    height: 3rem;
}

.instructor-name {
    text-transform: capitalize;
    width: 25rem;
    word-wrap: break-word;
    font-size: 1.875rem;
}

.instructor-name--redesign {
    color: var(--zumba-white);
}

.theme-strong .instructor-name {
    font-size: 1.75rem;
}
.theme-strong .instructor-name {
    text-transform: uppercase;
    font-family: var(--strong-font-uniform);
    font-size: 3rem;
    line-height: .6;
}

.theme-zumba .instructor-name {
    font-size: 2.625rem;
}
ul.instructor-details-list {
    font-size: .875rem;
    color: #484A55;
    list-style-type: disc;
    margin-left: 0;
}
ul.instructor-details-list.instructor-details-list--redesign {
    font-size: 1rem;
    margin: 0;
    color: var(--zumba-white);
}

.theme-zumba ul.instructor-details-list.instructor-details-list--redesign .z-b4 {
    font-size: 1rem;
}

ul.instructor-details-list li {
    display: inline-block;
    margin-left: .625rem;
    margin-right: 1rem;
}

.theme-strong ul.instructor-details-list li {
    font-family: var(--strong-font-default);
}

ul.instructor-details-list li:first-child {
    margin-left: 0;
    list-style-type: none;
}
.theme-zumba ul.instructor-details-list li:last-child {
    line-height: 0.3;
}
ul.instructor-details-list li:before {
    content: "";
    display: list-item;
    position: absolute;
    font-size: .4375rem;
    margin-left: -.4375rem;
}

.theme-strong ul.instructor-details-list li:before {
    margin: 0.4rem 0 0 -.4375rem;
}
ul.instructor-details-list li > svg {
    width: 1rem;
    height: 1rem;
    fill: var(--zumba-light-coral);
    vertical-align: text-top;
}

.theme-strong ul.instructor-details-list li > svg {
    fill: var(--strong-razzmatazz);
    margin-top: .25rem;
}
.theme-strong ul.instructor-details-list.instructor-details-list--redesign li > svg {
    fill: var(--strong-primary-orange);
}
.theme-zumba ul.instructor-details-list li > svg {
    margin-top: .15rem;
}
.theme-zumba ul.instructor-details-list.instructor-details-list--redesign li > svg {
    fill: var(--zumba-neon-green);
}

.theme-strong .qr-icon svg {
    fill: var(--strong-razzmatazz);
}
ul.instructor-details-list li > a {
    color: var(--zumba-gray-600);
    font-weight: 700;
    text-decoration: underline;
}

.theme-strong ul.instructor-details-list li > a {
    font-family: var(--strong-font-default);
}
.theme-strong ul.instructor-details-list.instructor-details-list--redesign li > a {
    color: var(--strong-primary-orange);
    text-decoration: none;
    font-weight: 400;
}

.theme-zumba ul.instructor-details-list.instructor-details-list--redesign li > a {
    color: var(--zumba-neon-green);
    text-decoration: none;
    font-weight: 400;
}

.leave-a-tip-icon svg {
    fill: var(--zumba-plum);
    position: relative;
    top: 0.4rem;
    width: 21px;
    height: 21px;
}

.theme-strong .leave-a-tip-icon svg {
    fill: var(--strong-razzmatazz);
}

.leave-a-tip-desktop {
    display: none;
}
.follow-mobile {
    display: none;
}

.desktop-buttons-container {
    display: block;
}
.cta-container--redesign :deep(.compact-desktop-only) {
    height: 3.125rem;
}
.follow-button :deep(.z-button) {
    fill: var(--zumba-white);
}
.follow-button.following :deep(.z-button) {
    fill: var(--zumba-white);
}
.follow-button :deep(.z-button.optimus:hover) {
    background: var(--zumba-white);
    color: var(--zumba-gray-800);
    fill: var(--zumba-gray-800);
    border-color: var(--zumba-white);
}
.follow-button.following :deep(.z-button:hover) {
    fill: black;
    border: 1px solid black;
}

.follow-button.following :deep(.z-button) {
    fill: var(--zumba-white);
}
.follow-button.following :deep(.z-button) {
    background: white;
    color: var(--zumba-gray-800);
    fill: var(--zumba-gray-800);
}

.follow-icon,
.following-icon {
    height: 1rem;
    width: 1rem;
}
.fitness_types {
    font-size: .75rem;
    display: flex;
    flex-wrap: wrap;
}
.fitness_types--redesign {
    color: var(--zumba-white);
}
.fitness-type-tag {
    margin-left: 0;
    margin-bottom: .5rem;
    margin-right:.5rem;
}
.fitness_types_column :deep(.tag) {
    background-color: var(--zumba-gray-100);
}
.licenses-and-certs-label {
    color: var(--strong-neutral-gray-200);
    text-transform: uppercase;
}
.licenses-and-certs-list {
    list-style-type: none;
    display: inline;
    margin-left: 0;
    font-family: var(--strong-font-default);
}
.licenses-and-certs-list .licenses-and-certs-list-item {
    display: inline;
}
.licenses-and-certs-list .licenses-and-certs-list-item:not(:last-child)::after {
    content: ", ";
}
.customized-licenses {
    font-size: 1rem;
    line-height: 1.6;
}

.headline {
    margin-bottom: .5rem;
}
.view-credentials-details {
    font-size: 1rem;
}
.cta-container {
    display: grid;
    grid-gap: 0.65rem;
    grid-auto-flow: column;
    margin: 0 auto;
    width: 100%;
    justify-content: flex-end;
}

.cta-container--redesign {
    margin: 0;
    width: auto;
    justify-content: flex-start;
    margin-bottom: 3rem;
}

:deep(.punisher svg) {
    fill: var(--zumba-white);
}

:deep(.punisher:hover svg) {
    fill: var(--strong-primary-coral);
}

.instructor-share-button-mobile {
    display: none;
}

.theme-strong .instructor-details {
    height: initial;
    margin-bottom: .25rem;
}

.share-button > :deep(button),
.tip-button > :deep(button),
.contact-button :deep(button) {
    padding: .5rem .75rem;
}

.contact-button :deep(.icon-only) {
    padding: 0;
}

.share-button svg {
    transform: scale(.8)translate(0px, -2.25px);
}

.mobile-button > :deep(button) {
    padding: 0.6rem 0.7rem 0.5rem 0.7rem;
    border-radius: 0.25rem;
}
.edit-mode-icon {
    cursor: pointer;
    position: relative;
    top: .1875rem;
    width: 1.125rem;
}

.theme-strong .website-action :deep(svg path) {
   fill: var(--strong-razzmatazz);
}

.theme-zumba .qr-icon {
    fill: var(--zumba-dark-coral);
}

.share-button svg {
    transform: scale(.8)translate(0px, -2.25px);
}

.share-button:hover svg {
    fill: var(--zumba-white);
}

.tipping-toggle {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
}

.instructor-share-button {
    display: flex;
    justify-content: end;
    gap: 0.75rem;
}
.instructor-details-editable {
    border-width: medium 0 0;
    width: 100%;
    grid-template-columns: 1fr;
    margin-top: 0.6rem;
}

.instructor-details-editable > .editable-row {
    border-top: 1px solid var(--zumba-gray-200);
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 14px
}

.editable-row .row-label {
    color: var(--zumba-gray-400);
}

.editable-row .action-icon {
    width: 18px;
    top: 7px;
    margin-left: 1rem;
    fill: var(--zumba-gray-800);
}

.editable-row .icons {
    width: 18px;
    top: 7px;
    margin-right: 1rem;
    fill: var(--zumba-gray-800);
}
.editable-row .icons:last-child {
    margin-right: 0;
}
.editable-row a {
    color: var(--zumba-plum);
}

.edit-mode-icon.disabled {
    fill: var(--zumba-gray-300);
    cursor: inherit;
}
.message-icon {
    cursor: inherit;
}
.editable-row .contact-icon {
    pointer-events: none;
}

.message-icon {
    pointer-events: none;
}
.theme-strong .website-title {
    color: var(--strong-primary-orange);
}
.theme-zumba .website-title {
    color: var(--zumba-neon-green);
}

.website-label,
:deep(.social-label) {
    font-size: .75rem;
    font-family: var(--strong-font-default);
    text-transform: uppercase;
    color: var(--strong-neutral-gray-200);
}

:deep(.social-label) {
    color: var(--strong-neutral-gray-200)!important;
}

.row-actions .social-item svg {
    position: relative;
    top: 7px;
    height: 1.25rem;
    width: 1.25rem;
    fill: var(--zumba-gray-300);
}

.row-actions .social-item + .social-item {
    margin-left: 0.5rem;
}

.row-actions .social-item.withValue svg {
    fill: var(--zumba-gray-800);
}

.leave-a-tip-mobile:hover svg {
    fill: var(--zumba-white);
}

.social-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;
    width: 100%;

}
 .social-section .column a {
	 margin-bottom: 0;
	 overflow: hidden;
	 text-overflow: ellipsis;
	 display: -webkit-box;
	 -webkit-line-clamp: 3;
	 -webkit-box-orient: vertical;
	 font-family: 'Inter';
	 font-style: normal;
	 font-weight: 400;
	 font-size: 16px;
	 line-height: 24px;
	 color: var(--zumba-plum);
}
 .social-section .column.social-networks ul {
	 list-style: none;
	 margin: 0;
	 padding: 0;
	 display: flex;
	 flex-direction: row;
	 align-items: center;
}
 .social-section .column.social-networks ul li {
	 margin-right: 10px;
}
 .social-section .column.social-networks ul li a {
	 color: #2c2d37;
}

.social-section :deep(.social-icon) {
    fill: var(--zumba-white);
}

.instructor-cover:deep(.hr) {
    width: 100%;
    margin: 0.8rem 0;
}

.hide-desktop {
    display: none;
}

.instructor-img::after {
    content: '';
    display: none;
    background-position-x: 1px;
    background-image: url('~@icons/zumba_background.svg?external');
    background-repeat: no-repeat;
    position: absolute;
    background-size: 17.375rem;
    height: 17.375rem;
    width: 17.375rem;
    z-index: 1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}
.instructor-img.profile-image-container-background-strong::after,
.instructor-img.profile-image-container-background-zumba::after {
    display: block;
}

.instructor-img::after {
    background-position-x: 0;
    background-size: 26.75rem;
    height: 26.625rem;
    width: 26.75rem;
}
.theme-zumba .instructor-img::after {
    background-position-x: 0;
    background-size: 23.0625rem;
    height: 23.0625rem;
    width: 23.0625rem;
}
.theme-strong .instructor-img::after {
    background-image: url('~@images/InstructorPage/strong_profile_photo_bg_desktop.svg?external');
}
.theme-zumba .instructor-img::after {
    background-image: url('~@images/InstructorPage/zumba_profile_photo_bg_desktop.svg?external');
}

.instructor-img:deep( .profile-image-container ) {
    position: relative;
    z-index: 2;
}

:deep(.profile-image-img) {
    border: none;
    width: 23.0625rem;
}

.section-divider {
    opacity: 0.3;
    font-size: 0;
}
.theme-strong .section-divider {
    margin: 1.5rem 0;
    border-bottom: 1px dashed var(--strong-neutral-gray-300);
}
.theme-zumba .section-divider {
    margin: 1.5rem 0;
    border-bottom: 1px dashed var(--zumba-gray-200);
}
.hr.section-divider::before {
    background-color: transparent;
}

@media only screen and (max-width: 64em) and (orientation : landscape) {
    .social:deep(.social-links > a.social-link) {
        margin-right: 0;
    }

    .instructor-container {
        grid-template-columns: 254px 1fr;
    }
}

@media only screen and (max-width: 63rem) {
    .headline {
        margin-bottom: .25rem;
    }
    .instructor-cover {
        margin-left: auto;
        margin-right: auto;
    }
    .licenses-and-certs-column {
        padding: 0 1rem;
    }
    .hide-desktop {
        display: block;
    }
    .cta-container {
        display: block; /* turn off grid */
        margin-bottom: 1rem;
    }
    .cta-container-mobile {
        margin: 0 auto 1rem;
    }
    .instructor-container {
        display: block;
    }

    .instructor-img {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        margin-bottom: 1.5rem;
        position: relative;
    }

    .instructor-img::after {
        background-position: center;
        background-size: 14.875rem;
        height: 14.75rem;
        width: 14.875rem;
    }
    .theme-zumba .instructor-img::after {
        background-position: center;
        background-size: 12.5rem;
        height: 12.5rem;
        width: 12.5rem;
    }
    .theme-strong .instructor-img::after {
        background-image: url('~@images/InstructorPage/strong_profile_photo_bg_mobile.svg?external');
    }
    .theme-zumba .instructor-img::after {
        background-image: url('~@images/InstructorPage/zumba_profile_photo_bg_mobile.svg?external');
    }

    :deep(.profile-image-img) {
        width: 12.5rem;
        margin: 1.2rem 0 0 1.2rem;
    }

    .instructor-img:deep( .profile-image-container ) {
        height: 14.75rem;
        width: 14.875rem;
        z-index: 2;
    }

    .instructor-type-tags {
        margin: 0 auto 1rem;
    }
    .theme-strong .second-hr {
        display: none;
    }

    .instructor-name {
        width: auto;
        font-size: 1.5rem;
    }

    .instructor-img:deep(.instructor-type) {
        transform: translate(100%, 100%);
        width: 2rem;
        height: 2rem;
        top: -3.5625rem;
        left: 2.625rem;
    }

    .instructor-img:deep(.edit-mode) {
        transform: scale(.8)translate(-110%, -50%);
    }

    .theme-strong .hr.section-divider {
        width: 85%;
        margin: .5rem auto;
    }

    .theme-zumba .hr.section-divider {
        width: 85%;
        margin: .5rem auto;
    }

    .instructor-img:deep(.profile-image-container svg.camera-svg ) {
        width: 1.5rem;
        height: 1.5rem;
    }

    .instructor-details-container {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .instructor-details {
        margin: 0 auto;
        text-align: center;
        display: block;
        min-height: initial;
    }

    ul.instructor-details-list {
        margin: 0 auto;
        padding: 0;
        line-height: 2.5;
        text-align: center;
    }

    .edit-mode ul.instructor-details-list {
        margin-bottom: .5rem;
    }

    ul.instructor-details-list.instructor-details-list--redesign:first-child {
        line-height: 1rem;
        padding: 1.2rem 0 0;
    }
    ul.instructor-details-list.instructor-details-list--redesign:nth-child(n+2) {
        padding: .5rem 0;
        line-height: 1.5rem;
    }

    ul.instructor-details-list.instructor-details-list--redesign.ratings:nth-child(n+2) {
        padding: 0;
        line-height: 2.5;
    }
    ul.instructor-details-list.instructor-details-list--redesign.rating-mobile {
        margin: .5rem auto;
        padding: 0;
        line-height: 2.5;
    }
    .follow-mobile {
        display: block;
    }

    .social-section {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        text-align: center;
        margin-bottom: 0.5rem;
        margin-top: .5rem;
    }
    .social-section .column {
        text-align: center;
    }
    .social:first-child {
        margin-bottom: 1rem;
    }
    .social-section:deep(.social-links) {
        margin-top: 0;
        margin-left: 0;
    }

    :deep(.social-link--mobile-large a.social-link svg) {
        width: 1.8rem;
        height: 1.8rem;
    }
    .theme-strong .social:first-child {
        margin-bottom: .5rem;
    }

    .social-section .column h2 {
        text-align: center;
    }

    :deep(.social) {
        margin-top: .5rem;
    }
    :deep(.social:last-child) {
        margin-top: 2rem;
    }

    :deep(.social-label) {
        display: inline-block;
    }

    .buttons-container {
        display: flex;
        gap: 0.5rem;
    }
    .buttons-container .contact-button {
        flex-grow: 1;
    }
    .buttons-container > * {
        flex-basis: 48px;
    }

    .instructor-details-editable {
        margin-top: 1rem;
    }

    .row-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .instructor-details-editable > .editable-row {
        display: block;
        justify-content: space-between;
        align-items: center;
    }

    .editable-row .action-icon {
        top: 0;
    }

    .editable-row .icons {
        top: 0;
        margin-right: 1rem;
    }

    .instructor-details-editable .row-actions {
        margin-top: .5rem;
    }

    .fitness_types {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
        display: grid;
        padding: .5rem 1rem 0;
    }
    .fitness_types_column {
        margin-bottom: 0.5rem;
        padding-right: 0;
    }
    .fitness_types_column:last-child {
        margin-bottom: 0;
    }
    .fitness-type-tag {
        margin-right: 0.5rem;
        margin-top: 0.5rem;
    }
    .theme-strong .fitness_types {
        margin-top: 0;
    }

    .website-action {
        margin-top: -1rem;
    }

    .instructor-details-actions {
        width: 100%;
        margin-top: 1rem;
        padding: 0 2rem 2rem 2rem;
    }

    .view-credentials-details-container {
        margin-bottom: .75rem;
    }

    .social:deep(.social-links > a.social-link) {
        margin-right: 0;
    }
    .instructor-name {
        margin: 0;
        width: auto;
    }

    .website-icon {
        display: inline;
    }

    .theme-strong ul.instructor-details-list li:before {
        margin: .7rem 0 0 -.4375rem;
    }

    .instructor-share-button-mobile {
        display: grid;
        grid-template-columns: 80fr 20fr;
    }

    .website-label {
        margin-bottom: .5rem;
    }

    .hide-mobile {
        display: none;
    }
}

@media only screen and (max-width: 30.063rem) {
    .instructor-details-container{
        display: none;
    }
    .instructor-details-container-mobile{
        display: block;
        max-width: 18.75rem;
        margin: 0 auto -1.5rem;
    }
    .mobile-list {
        position: relative;
        top: -1rem;
    }
    .fitness_types_column {
        flex: auto;
        padding: 0;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (min-width: 48rem) {
    .theme-strong .instructor-img::after,
    .theme-zumba .instructor-img::after {
        background: none;
    }
    .instructor-img:deep( .profile-image-container ) {
        position: relative;
        z-index: 2;
        background-position-x: 0;
        background-size: 26.75rem;
        background-repeat: no-repeat;
        height: 26.625rem;
        width: 26.75rem;
        transform: translate(-50%, -50%);
        top: 40%;
        left: 45%;
        padding: 1.8rem 0 0 1.8rem;
    }
    .theme-zumba .instructor-img:deep( .profile-image-container ) {
        background-size: 23.0625rem;
        height: 23.0625rem;
        width: 23.0625rem;
    }
    .theme-strong .instructor-img:deep( .profile-image-container ) {
        background-image: url('~@images/InstructorPage/strong_profile_photo_bg_desktop.svg?external');
    }
    .theme-zumba .instructor-img:deep( .profile-image-container ) {
        background-image: url('~@images/InstructorPage/zumba_profile_photo_bg_desktop.svg?external');
    }

    ul.instructor-details-list.instructor-details-list--redesign {
        margin: .5rem 0;
    }

    .contact-button :deep(button.compact-desktop-only),
    .follow-button :deep(button.compact-desktop-only) {
        padding: 0 2.25rem;
        font-size: 1rem;
    }

    .website-action {
        padding-right: 2.25rem;
    }
}
</style>
