<script setup lang="ts">
import { PropType, computed, reactive, watch } from "vue"
import ZInput from "@components/Core/ZInput.vue"
import ZButton from "@components/Core/ZButton.vue"
import InstaIcon from '@bx-icons/logos/bxl-instagram-alt.svg'
import InstagramFeed from "./InstagramFeed.vue"
import ToggleSwitch from "@components/Core/ToggleSwitch.vue"
import Modal from "@components/Core/Modal.vue"
import { isMobile } from "@ts/Util/responsiveness"
import { interpolate } from "@ts/Util/text"

const props = defineProps({
    username: {
        type: String,
        default: () => ''
    },
    instructorName: {
        type: String,
        default: () => ''
    },
    enabled: {
        type: Boolean,
        default: () => false
    },
    show: {
        type: Boolean,
        default: () => false
    },
    edit: {
        type: Boolean,
        default: () => false
    },
    loading: {
        type: Boolean,
        default: () => false
    },
    showDisconnectModal: {
        type: Boolean,
        default: () => false
    },
    enableRedesign: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object as PropType<TranslationKeys>,
        default: (): TranslationKeys => translationDefaults,
    }
})

const emit = defineEmits([
    'connect-requested',
    'visibility-changed',
    'disconnect-modal-requested',
    'disconnect-requested',
    'disconnect-request-cancelled',
])

const state = reactive({
    usernameInput: props.username,
    toggled: props.show,
    hasError: false,
})
watch (() => props.show, (newVal) => {
    state.toggled = newVal
})

watch(() => state.toggled, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('visibility-changed', newVal)
    }
})

watch(() => state.usernameInput, (newVal, oldVal) => {
    checkUsername(state)
})

const publicLink = computed(() => (
    '<a href="https://help.instagram.com/225222310104065">' + props.t.connect_public + '</a>'
))

const embedLink = computed(() => (
    '<a href="https://help.instagram.com/252460186989212/?cms_platform=iphone-app&helpref=platform_switcher">' + props.t.connect_embed + '</a>'
))

</script>
<script lang="ts">
const translationDefaults = {
    title: 'Instagram Feed',
    no_feed: 'No Instagram feed to display. Connect it to start sharing.',
    username: 'Instagram username',
    usernameError: 'Invalid Instagram username',
    connect: 'Connect',
    connect_note: 'When your feed is connected it will update automatically and appear directly on this instructor page. You can hide it anytime.',
    connect_instructions: 'To display your Instagram profile, please make sure that your account is set to {0} and that the {1} option is turned ON.',
    connect_public: 'public',
    connect_embed: 'embed',
    hide: 'Hide',
    show: 'Show',
    hidden: 'Your Instagram feed is hidden. Click on &quot;Show&quot; to display it!',
    disconnect: 'Disconnect',
    disconnect_title: 'Disconnect my Instagram feed',
    disconnect_message: 'Are you sure you want to disconnect your Instagram feed from your page?',
    disconnect_yes: 'Yes, disconnect',
    cancel: 'Cancel',
}
type TranslationKeys = typeof translationDefaults

/**
 * Validates user is not a URL
 */
const checkUsername = (state: object) => {
    state.usernameInput = state.usernameInput?.trim();
    /**
     * An Instagram username is limited to 30 characters and must contain only letters, numbers, periods, and underscores.
     */
    if (state.usernameInput.match(/[^a-zA-Z0-9._]/g) || state.usernameInput.length > 30) {
        state.hasError = true;
    } else {
        state.hasError = false;
    }
}

</script>
<template>
    <div
        :class="{
            'instagram-redesign-container' : enableRedesign
        }"
    >
        <div
            v-if="enableRedesign"
            class="header-container"
        >
            <h4
                class="instructor-section__title--redesign z-h1"
            >
                {{ t.title }}
            </h4>
            <div
                v-if="enabled && edit"
                class="toggle-container"
            >
                <div class="toggle">
                    <toggle-switch
                        v-if="enabled && edit"
                        v-model="state.toggled"
                        :disabled="loading"
                    >
                        <template #label>
                            <span class="toggle-text f-inter">{{ state.toggled ? t.hide : t.show }}</span>
                        </template>
                    </toggle-switch>
                </div>
            </div>
        </div>
        <div
            v-else
            class="title-container"
        >
            <h5 class="title f-inter">
                {{ t.title }}
            </h5>
            <div class="toggle">
                <toggle-switch
                    v-if="enabled && edit"
                    v-model="state.toggled"
                    :disabled="loading"
                >
                    <template #label>
                        <span class="toggle-text f-inter">{{ state.toggled ? t.hide : t.show }}</span>
                    </template>
                </toggle-switch>
            </div>
        </div>

        <div
            v-if="!enabled || !state.toggled"
            class="container"
        >
            <img
                src="@images/InstructorPage/InstaLogo.png"
                alt="Instagram Logo"
                class="logo"
            >
            <div
                v-if="!enabled"
                class="setup"
            >
                <p class="no-feed z-b2 f-inter">
                    {{ t.no_feed }}
                </p>
                <div class="form">
                    <z-input
                        v-model="state.usernameInput"
                        class="input"
                        :name="t.username"
                        :label="t.username"
                        :error="state.hasError ? t.usernameError : ''"
                    >
                        <template #right-slot>
                            <InstaIcon />
                        </template>
                    </z-input>
                    <z-button
                        class="connect-button"
                        variety="optimus"
                        wide="mobile-only"
                        :disabled="loading || state.hasError"
                        @click="() => $emit('connect-requested', state.usernameInput)"
                    >
                        {{ t.connect }}
                    </z-button>
                </div>
            </div>
            <div v-else>
                <!-- eslint-disable vue/no-v-html -->
                <p
                    class="hidden-text z-b2 f-inter"
                    v-html="t.hidden"
                />
                <!-- eslint-enable vue/no-v-html -->
            </div>
        </div>
        <div
            v-else
            class="instagram-container"
        >
            <instagram-feed
                v-if="state.toggled"
                :username="username"
                :instructor-name="instructorName"
            />
        </div>
        <div
            v-if="edit"
            class="connect-note"
        >
            <p class="note z-b4 f-inter">
                {{ t.connect_note }}
            </p>
            <!-- eslint-disable vue/no-v-html -->
            <p
                class="instructions z-b4 f-inter"
                v-html="interpolate(t.connect_instructions, [
                    publicLink,
                    embedLink
                ])"
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>
        <a
            v-if="enabled && edit"
            class="disconnect-button z-b2 f-inter"
            href="#"
            @click.prevent="() => $emit('disconnect-modal-requested')"
        >
            {{ t.disconnect }}
        </a>
        <modal
            :show="showDisconnectModal"
            :show-close-button="true"
            :mobile-drawer="isMobile()"
            :custom-styles="{
                modal: {
                    width: '47.25rem',
                    maxWidth: '100%',
                }
            }"
            @closed="() => $emit('disconnect-request-cancelled')"
        >
            <div class="modal-body">
                <h4 class="z-h4 disconnect-modal-title">
                    {{ t.disconnect_title }}
                </h4>
                <p class="diconnect-message z-b2 f-inter">
                    {{ t.disconnect_message }}
                </p>
            </div>
            <div class="modal-button-container">
                <z-button
                    class="modal-button"
                    variety="optimus"
                    wide="always"
                    :disabled="loading"
                    @click="() => $emit('disconnect-requested')"
                >
                    {{ t.disconnect_yes }}
                </z-button>
                <z-button
                    class="modal-button"
                    variety="phoenix"
                    wide="always"
                    :disabled="loading"
                    @click="() => $emit('disconnect-request-cancelled')"
                >
                    {{ t.cancel }}
                </z-button>
            </div>
        </modal>
    </div>
</template>
<style scoped lang="scss">
.title-container {
    display: flex;
    justify-content: space-between;
    .toggle {
        width: 5.1875rem;
    }
}
.title {
    margin: 0;
}
.header-container {
    padding-top: 2.5rem;
    text-align: center;
    margin: 0 auto;
    padding: 0 .2rem;
}

.toggle-container {
    display: flex;
    justify-content: flex-end;
    .toggle {
        width: 5.1875rem;
    }
}
.theme-strong .title,
.theme-strong .disconnect-modal-title {
    font-family: var(--strong-font-default);
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
}
.logo {
    width: 5rem;
    height: 5rem;
    margin-top: 2.5rem;
}

.toggle-text {
    margin-right: 0.625rem;
}
.no-feed, .hidden-text {
    margin: 1.25rem 1rem 0 1rem;
    text-align: center;
}
.setup {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form {
    width: 100%;
    margin-top: 1.875rem;
    .connect-button {
        margin-top: 1rem;
    }
}

.modal-button-container {
    .modal-button {
        margin-top: 1.5625rem;
    }
}

.modal-body {
    text-align: center;
}

.diconnect-message {
    margin: 1.25rem 0 0 0;
}

.disconnect-button {
    color: var(--zumba-plum);
    display: inline-block;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    text-decoration: none;
    margin-top: 1.25rem;
}
.theme-strong .disconnect-button {
    color: var(--strong-razzmatazz);
}
.connect-note {
    margin-top: 1.875rem;
    padding: 0 1.25rem;
    text-align: center;

    p {
        margin-top: 0;

        :deep(a) {
            text-decoration: none;
        }
    }
}
.theme-zumba .connect-note p :deep(a) {
    color: var(--zumba-plum);
}
.theme-strong .connect-note p :deep(a) {
    color: var(--strong-razzmatazz);
}

.instagram-container {
    margin-top: 1.875rem;
}

.instagram-redesign-container {
    background: var(zumba-white);
    margin: 0 -1rem .5rem;
    padding: 2rem 2rem;
}

.toggle-container :deep(.toggle) {
    margin-top: 0;
}

.theme-zumba .instructor-section__title--redesign  {
    font-size: 1.875rem;
    font-weight: 700;
    margin-top: 1.6rem;
}

.theme-strong .instructor-section__title--redesign  {
    font-family: var(--strong-font-uniform);
    font-size: 2.25rem;
    line-height: 2.25rem;
    margin: 1.8rem 0 .85rem 0;
}

@media only screen and (min-width: 29rem) {
    .logo {
        margin-top: 1.875rem;
    }
    .form {
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        .input {
            width: 18.4375rem;
        }

        .connect-button {
            margin-top: 0;
        }
    }

    .disconnect-modal-title {
        margin-top: 2.3125rem;
    }
    .modal-button-container {
        display: flex;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
        margin: 3.3125rem 0 1.8125rem 0;

        .modal-button {
            width: 15.625rem;
            margin-top: 0;
        }
    }
    .connect-note {
        p {
            margin: 0;
        }
    }
    .header-container {
        padding: 0 2rem;
    }

    .redesign-top-padding {
        padding-top: 1rem;
    }

    .instagram-redesign-container {
        margin: 0 auto;
        max-width: 1040px;
        padding: .5rem 2rem 2rem;
    }

    .theme-zumba .instructor-section__title--redesign  {
        font-size: 3.125rem;
        font-weight: 800;
        margin-top: 2.8rem;
    }

    .theme-strong .instructor-section__title--redesign {
        font-family: var(--strong-font-uniform);
        font-size: 3.125rem;
        font-weight: 500;
        margin: 2.96rem 0 .7rem 0;
    }

}
</style>
