#layout-page-content {
  background: var(--zumba-gray-100);
}

.theme-strong #layout-page-content {
  background: var(--strong-neutral-gray-100);
}

.instructor-section {
  background: #FFFFFF;
  border-radius: 4px;
  margin: 0 -1rem 2.5rem;
  padding: 2rem 1rem;
}

.instructor-section--redesign {
  background: none;
}

.instructor-instagram-section {
    background-color: var(--zumba-white);
    margin: 0 -1rem;
}

.instructor-about-section {
    background-color: var(--zumba-white);
    margin: 0 -1rem;
}

.instructor-section.instructor-cover--redesign {
  background: none;
  padding: 2rem 0;
}

.theme-zumba .instructor-section.instructor-cover--redesign {
  padding-top: 3.75rem;
}

.cm-main {
  padding-top: 4.875rem;
  position: relative;
}

.cm-main .instructor-section {
  margin: 0 0 2.5rem;
}

.cm-main .instructor-instagram-section {
  margin: 0 0 2.5rem;
}

.cm-main .instructor-about-section {
  margin: 0 auto
}

.cm-main .instructor-reviews-section {
  margin: 0 auto;
}

@media only screen and (min-width: 48rem) {
    .instructor-section {
        margin: 0 auto 2.5rem;
        max-width: 1040px;
        padding: 2rem;
    }

    .cm-main {
        padding-top: 3rem;
    }

    .cm-main .instructor-section {
        margin: 0 auto 2.5rem;
    }

    .cm-main .instructor-instagram-section {
        margin: 0 auto;
    }
}