<template>
    <Modal
        :show="show"
        close-when-click-on-background
        close-when-type-esc
        :show-close-button="!isMobile"
        enable-scroll
        mobile-drawer
        :custom-styles="customModalStyles"
        @close="$emit('close')"
    >
        <div
            id="modal-content"
            class="view-credentials-details"
        >
            <div class="credential-details">
                <div class="profile-img">
                    <ProfilePictureDefault
                        :t="t"
                        :profile-photo-url="profilePhotoUrl"
                        :style="{ transform: 'scale(.8)' }"
                    />
                </div>
                <div class="inst-details">
                    <h4 class="z-h4 instructor-name f-inter">
                        {{ instructor.display_name }}
                    </h4>
                    <span class="instructor-details-list">
                        <span
                            v-if="instructor.location"
                            class="z-b3 f-inter location"
                        >
                            {{ instructor.location }}
                        </span>
                        <span
                            class="z-b3 f-inter location"
                        >
                            {{ membershipTypeLabel }}
                        </span>
                    </span>
                </div>
            </div>
            <HorizontalSeparator />
            <div class="license-certification">
                <p class="headline z-b4 f-inter">
                    {{ t.additionalLicensesCertifications }}
                </p>
                <span
                    class="z-b3 f-inter"
                >
                    {{ licenses }}
                </span>
            </div>
            <div class="credentials">
                <profile-credentials
                    :t="t"
                    :license="license"
                    :education="education"
                    :milestone="milestone"
                    :enable-noom-badge="enableNoomBadge"
                />
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import Modal from '@components/Core/Modal.vue'
import { PropType, defineComponent, watch, computed, Ref } from 'vue'
import ProfilePictureDefault from '@modules/InstructorPage/ProfilePictureDefault.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import { Instructor } from '@modules/InstructorPage/InstructorCover.vue'
import ProfileCredentials from '@modules/InstructorPage/ProfileCredentials.vue'
import {BadgeData } from '@modules/InstructorPage/ProfileBadges.vue'
import { isMobileReactive } from '@ts/Util/responsiveness'

export default defineComponent({
    name: 'VieCredentialModal',
    components: {
        HorizontalSeparator,
        Modal,
        ProfileCredentials,
        ProfilePictureDefault
    },
    props: {
        t: {
            type: Object,
            default: () => ({
                licenseToTeach: 'License to Teach',
                profileImageAlt: 'Instructor picture',
                print: 'print',
                additionalLicensesCertifications: 'Additional Licenses and Certifications'
            })
        },
        show: {
            type: Boolean,
            required: true,
            default: false
        },
        instructor: {
            type: Object as PropType<Instructor>,
            required: true
        },
        profilePhotoUrl: {
            type: String,
            default: '',
        },
        style: {
            type: Object,
            default: () => ({})
        },
        license: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        education: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        milestone: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        licenses: {
            type: String,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    },

    emits: [
        'close'
    ],

    setup(props) {
        const formatDate = (dateTime : string) => {
            if (!dateTime) {
                return null
            }
            const date : Date = new Date(dateTime)
            return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        }

        const  membershipTypeLabel = computed(() => {
            const membershipType = props.instructor.membership
            let joinDate = props.instructor.join_date ? formatDate(props.instructor.join_date) : null;

            if (!membershipType) {
                return ''
            } else {
                return  membershipType + ", " +  joinDate
            }
        })

        const isMobile: Ref<boolean> = isMobileReactive();
        const customModalStyles = computed<object>(() => {
            return {
                modal: {
                    border: 0,
                    width: isMobile.value ? '100%' : '46.875rem',
                    paddingTop: isMobile.value ? '3.8125rem' : '2.5rem',
                    paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
                    paddingLeft: isMobile.value ? '1rem' : '2.5rem',
                    paddingRight: isMobile.value ? '1rem' : '2.5rem',
                    borderRadius: isMobile.value ?  '0.5rem 0.5rem 0 0' : '0.25rem'
                }
            }
        })

        return {
            isMobile,
            membershipTypeLabel,
            formatDate,
            customModalStyles
        };
    },

})

</script>

<style scoped>
.instructor-name {
    text-transform: capitalize;
}
.headline {
    color: var(--zumba-gray-400);
}
.license-certification{
    margin-bottom: 1.5rem;
}

.credential-details {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.profile-img {
    height: 5.625rem;
    margin-bottom: 1rem;
    width: 5.625rem;
}
.inst-details {
    text-align: center;
}
.profile-img :deep(img) {
    max-width: 100%;
}

.profile-img :deep(svg) {
    max-width: 100%;
    max-height: 90px;
}
.instructor-details-list {
    margin-top: 0.5rem;
}

.view-credentials-details {
    max-height: 70vh;
    overflow: scroll;
}


.instructor-details-list span + span::before {
    background: var(--zumba-gray-700);
    border-radius: 100%;
    content: " ";
    display: inline-block;
    height: 3px;
    margin: 0 0.5rem;
    vertical-align: middle;
    width: 3px;
}

@media only screen and (min-width: 48rem) {
    .view-credentials-details {
        max-height: initial;
        overflow: auto;
    }
    .credential-details {
        flex-direction: row;
    }
    .profile-img {
        margin-right: 1.5rem;
    }

    .inst-details {
        text-align: left;
    }
}
</style>
