<template>
    <section
        class="instructor-reviews-section"
    >
        <div class="instructor-reviews">
            <div
                id="instructor-reviews"
                class="reviews-header"
            >
                <h4 class="instructor-section__title f-inter z-h1">
                    {{ t.title }}
                </h4>
            </div>
            <div
                v-if="state.reviews.length"
            >
                <div class="review-cta f-inter z-b3">
                    <span v-if=" state.ratingScoreFormatted >= 3">
                        <StarFilledSvg /> {{ state.ratingScoreFormatted }}
                        <span>·</span>
                        <strong>{{ state.numRatesFormatted }} {{ t.ratings }} &nbsp; | &nbsp; {{ state.numReviewsFormatted }} {{ t.reviews }}</strong>
                    </span>
                    <CopyableText
                        v-if="editMode"
                        href="#"
                    >
                        <template #default="{ copyTextToClipboard }">
                            <strong>
                                <a
                                    href="javascript:void(0)"
                                    class="rate-now f-inter"
                                    @click.prevent="copyTextToClipboard(rateLink)"
                                >
                                    {{ t.copyRatingLink }}
                                </a>
                            </strong>
                        </template>
                        <template #copied>
                            {{ t.copied }}
                        </template>
                    </CopyableText>
                    <a
                        v-else
                        href="javascript:void(0)"
                        class="rate-now f-inter z-b4"
                        @click="handleRateNowLink"
                    >
                        <b>
                            {{ t.rateNow }}
                        </b>
                    </a>
                </div>
                <div
                    v-if="state.showSelfRatingError"
                    class="review-cta__self-rate-error"
                >
                    {{ t.youCannotRateYourself }}
                </div>
                <div>
                    <div class="carousel-items">
                        <carousel
                            :class="{ 'safari': checkBrowser() }"
                            inline
                            arrows-top
                            :enable-redesign="true"
                        >
                            <template
                                v-for="(reviewItem, index) in reviews"
                                :key="String(reviewItem.pid) + '-' + String(reviewItem.created)"
                                #[`slide${index}`]
                            >
                                <ReviewRedesign
                                    :review="reviewItem"
                                    :t="t"
                                />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
            <div
                v-else-if="editMode && !state.reviews.length"
            >
                <div class="no-reviews z-b3 f-inter">
                    {{ t.askForSomeLove }}
                    <copyable-text>
                        <template #default="{ copyTextToClipboard }">
                            <a
                                class="f-inter z-b3"
                                @click="copyTextToClipboard(rateLink)"
                            >
                                {{ t.copyRatingLink }}
                            </a>
                        </template>
                        <template #copied>
                            {{ t.copied }}
                        </template>
                    </copyable-text>
                </div>
            </div>
            <div
                v-else
            >
                <div class="no-reviews z-b3 f-inter other-instructor">
                    <div
                        v-if=" state.ratingScoreFormatted >= 3"
                        class="reviews-count"
                    >
                        <StarFilledSvg /> {{ state.ratingScoreFormatted }}
                        <span>·</span>
                        <strong>{{ state.numRatesFormatted }} {{ t.ratings }} | {{ state.numReviewsFormatted }} {{ t.reviews }}</strong>
                    </div>

                    {{ t.beFirstOneShowLove }}

                    <a
                        href="#"
                        class="f-inter"
                        @click.prevent="handleRateNowLink"
                    >
                        {{ t.rateNow }}
                    </a>

                    <div
                        v-if="state.showSelfRatingError"
                        class="review-cta__self-rate-error self-rate-spacing"
                    >
                        {{ t.youCannotRateYourself }}
                    </div>
                </div>
            </div>
            <!-- teleport to escape if section is invisible -->
            <Teleport to="body">
                <RateInstructorHandler
                    v-model:show="state.showRating"
                    mode="rate"
                    :t="t"
                    :is-logged-in="isLoggedIn"
                    :rating-submitted="instructorRatingSubmitted"
                    :rating="studentInstructorRating"
                    :rate-link="rateLink"
                    :rate-instructor-action="rateInstructorAction"
                    :instructor-name="instructorName"
                    :instructor-pid="instructorPid"
                    @rate-instructor:reviews-updated="updateRatingsAndReviews"
                />
            </Teleport>
            <div class="bottom-left" />
            <div class="bottom-right" />
        </div>
    </section>
</template>

<script setup lang="ts">
import {PropType, computed, reactive} from 'vue'
import { ReviewData } from '@components/Reviews/Review.vue'
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg'
import { default as ReviewRedesign } from '@components/Reviews/ReviewRedesign.vue'
import RateInstructorHandler, {RatingAndReviewData} from "@modules/Rating/RateInstructorHandler.vue";
import CopyableText from "@components/Core/CopyableText.vue";
import { currentUserLocale } from "@ts/Util/i18n";
import Carousel from '@components/Util/Carousel.vue'

const props = defineProps({
    ratingScore: {
        type: Number,
        required: true,
        validator: (value : number) => value >= 0 && value <= 5,
    },
    showRatingModalInitially: {
        type: Boolean,
        required: true,
    },
    editMode: {
        type: Boolean,
        required: true,
    },
    isOwnProfile: {
        type: Boolean,
        required: true,
    },
    instructorName: {
        type: String,
        required: true,
    },
    isLoggedIn: {
        type: Boolean,
        required: true,
    },
    numReviews: {
        type: Number,
        default: 0,
        validator: (value : number) => value >= 0,
    },
    reviews: {
        type: Array as PropType<ReviewData[]>,
        required: true,
    },
    rateLink: {
        type: String,
        required: true
    },
    instructorRatingSubmitted: {
        type: String,
        required: true
    },
    studentInstructorRating: {
        type: [Number, String],
        required: true,
    },
    instructorPid: {
        type: String,
        required: true,
    },
    rateInstructorAction: {
        type: String,
        required: true,
    },
    t: {
        type: Object,
        default: () => ({
            title: 'Ratings',
            ratings: 'Ratings',
            reviews: 'reviews',
            rateNow: 'Rate Now',
            showMore: 'Show More',
            at: 'at',
            rateYourExperience:'Rate your experience with {0}',
            instructorRating: 'Instructor rating',
            ratingSubmitted:'Rating submitted',
            reviewHeader :'Leave a review',
            reviewBody :'Share a review about this instructor.',
            reviewPlaceHolder: 'How was their class, their coaching style?',
            ratingSubmittedOn :'Rating submitted on {0} at {1}',
            skipForNow :'Skip for now',
            submit :'Submit',
            cancel: 'Cancel',
            copyRatingLink: 'Copy rating link',
            copied: 'Copied!',
            askForSomeLove: 'Ask for some love from your fans.',
            youCannotRateYourself: 'You cannot rate yourself.',
            beFirstOneShowLove: 'This instructor doesn’t have any review to show for now. Be the first to show some love!',
        })
    }
})


const state = reactive({
    ratingScore: props.ratingScore,
    numReviews: props.numReviews,
    reviews: (props.reviews || []).slice(0) as Array<ReviewData>,
    visibleRecords: 6,
    visibleReviews: computed(() => {
        return state.reviews.slice(0, state.visibleRecords)
    }),
    numReviewsFormatted: computed(() => {
        const reviews = (props.reviews || []).filter((rate) => {
            return !!rate.comment;
        });
        return reviews.length;
    }),
    numRatesFormatted: computed(() => {
        return state.numReviews.toString();
    }),
    ratingScoreFormatted: computed(() => {
        const score = Math.round(state.ratingScore * 10) / 10;
        return Intl.NumberFormat(currentUserLocale(), {
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 2,
        }).format(score);
    }),
    showRating: Boolean(
        props.showRatingModalInitially &&
            !props.isOwnProfile &&
            !props.editMode &&
            props.isLoggedIn
    ),
    showSelfRatingError: false,
})

const updateRatingsAndReviews = (ratingData: RatingAndReviewData) => {
    state.reviews = ratingData.reviews
    state.numReviews = ratingData.num_reviews
    state.ratingScore = ratingData.rating_score
}

function tryToShowRatingModal() {
    if (props.isOwnProfile || props.editMode) {
        state.showSelfRatingError = true
    } else {
        state.showRating = true
    }
}

function redirectAndRateLater() {
    window.location.href = '/login?redirect=' + encodeURIComponent(props.rateLink)
}

function handleRateNowLink() {
    if (props.isLoggedIn) {
        tryToShowRatingModal()
        return
    }

    redirectAndRateLater()
}

function checkBrowser() {
    return navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
}

</script>
<style scoped>

.instructor-reviews-section {
    margin-top: 2rem;
    background-color: var(--zumba-success-green);
    margin: 0 -1em;
    position: relative;
}

.theme-strong .instructor-reviews-section {
    background-color: var(--strong-secondary-dark-blue);
    color: var(--zumba-white);
}


.theme-strong .instructor-reviews-section .bottom-left {
    display: none;
}

.instructor-reviews {
    margin: 0 auto;
    max-width: 1040px;
    padding: 2rem;
}

.carousel.arrows-top .control-button.prev {
    z-index: 2;
}

.instructor-section__title {
    font-size: 1.875rem;
}
 
.theme-strong .instructor-section__title {
    color: var(--zumba-white);
    font-family: var(--strong-font-uniform);
    font-size: 2.25rem;
    font-weight: 500;
}

.instructor-reviews {
    text-align: center;
}

.reviews-header {
    padding: .2rem 0 0;
    text-align: center;
}

.review-cta {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}

.review-cta svg {
    width: 1.125rem;
    vertical-align: bottom;
}

.theme-strong .review-cta svg {
    fill: var(--zumba-white);
    vertical-align: text-bottom;
}

.review-cta span {
    margin: 0 0.5rem;
}

.review-cta strong {
    margin-right: 0.25rem;
    text-transform: lowercase;
}

.review-cta a {
    color: var(--zumba-gray-800);
    margin-left: 0.5rem;
    font-size: 1rem;
    margin: .5rem;
}

.rate-now {
    border-bottom: 1px solid;
    display: inline;
    white-space: nowrap; 
}

.theme-strong .review-cta a {
    color: var(--zumba-white);
}

.no-reviews {
    text-align: center;
    margin: 1.5rem 0;
}

.no-reviews.other-instructor {
    text-align: left;
}

.no-reviews.other-instructor .reviews-count {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
}

.no-reviews a {
    color: var(--zumba-gray-800);
    border-bottom: 1px solid;
    font-weight: 700;
}

.theme-strong .no-reviews a {
    color: var(--zumba-white);
}

.review-cta__self-rate-error {
    color: var(--zumba-error-red);
    margin-top: -.5rem;
    margin-bottom: 1.4rem;
}

.review-cta__self-rate-error.self-rate-spacing {
    margin-top: 1rem;
}

.review-list {
    display: grid;
    grid-template-columns: 1fr;
}
.review-list-desktop {
    display: none;
}

.carousel-items :deep(.carousel.arrows-top .control-icon.control-icon--redesign) {
    fill: var(--zumba-grey-800);
}

.theme-strong .carousel-items :deep(.carousel.arrows-top .control-icon.control-icon--redesign) {
    fill: var(--zumba-white);
}

.theme-zumba .carousel-items :deep(.control-button--redesign.control-button.disabled > .control-icon) {
    fill: var(--zumba-white);
}

.theme-strong .carousel-items :deep(.control-button--redesign.control-button.disabled > .control-icon) {
    fill: var(--zumba-gray-400);
}

.theme-strong .rate-now {
    margin-top: .5rem;
}

:deep(.safari .slide-item) {
    min-height: fit-content;
}

@media only screen and (min-width: 48rem) {
    .instructor-reviews-section::before,
    .instructor-reviews-section::after,
    .instructor-reviews-section .bottom-left,
    .instructor-reviews-section .bottom-right {
        content: '';
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .instructor-reviews-section::before {
        width: 472px;
        height: 104px;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/flower-left.svg');
        top: 0;
        left: 0;
    }

    .theme-strong .instructor-reviews-section::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height:100%;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/isolation_mode_left.svg');
    }

    .instructor-reviews-section::after {
        width: 241px;
        height: 139px;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/flower-right.svg');
        top: 0;
        right: 0;
    }

    .theme-strong .instructor-reviews-section::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 12.063rem;
        height: 100%;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/isolation_mode_right.svg');
        background-size: contain;
    }

    .instructor-reviews-section .bottom-right {
        width: 336px;
        height: 226px;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/flower-bottom-right.svg');
        bottom: 0;
        right: 0;
    }

    .theme-strong .instructor-reviews-section .bottom-right {
        display: none;
    }

    .instructor-reviews-section .bottom-left {
        width: 187px;
        height: 40px;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/6/flower-bottom-left.svg');
        bottom: 0;
        left: 7.063rem;
    }

    .reviews-header {
        padding: 1.5rem 0 0;
    }

    .instructor-section__title {
        font-size: 3.125rem;
    }

    .theme-strong .instructor-section__title {
        font-size: 3.125rem;
    }

    .review-list {
        display: none;
    }
    .review-list-desktop {
        display: flex;
        align-items: stretch;
        width: 100%;
    }
    .review-list__show-more {
        display: none;
    }
    .review-list__show-more-desktop {
        display: block;
        margin-top: 1.5rem;
        text-align: center;
    }
    .review-list-column {
        display: flex;
        flex-flow: column;
        margin-right: 4rem;
        width: 50%;
    }

    #instructor-reviews:target {
        scroll-margin-top: 7rem;
    }

    .review-cta {
        align-items: center;
        display: inline-flex;
        margin: .5rem 0 1.6rem;
    }

    .review-cta a {
        margin: 0.12rem 0 0;
        color: var(--zumba-gray-800);
    }

    .theme-strong .review-cta a {
        margin: .5rem 0 0;
        color: var(--zumba-white);
    }

    .no-reviews.other-instructor {
        text-align: center;
    }

    .no-reviews {
        margin: 1.5 0 0;
    }

}
</style>