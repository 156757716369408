<template>
    <Modal
        :show="showModal"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="() => handleClosedModal()"
    >
        <div class="instructor-website-modal">
            <h3 class="modal-headline f-inter">
                {{ t.title }}
            </h3>
            <p class="instructions">
                {{ t.instructions }}
            </p>
            <div class="main-container">
                <ZInput
                    v-model="url"
                    name="title"
                    type="text"
                    :required="true"
                    :label="t.pageLink"
                    :error="internalError"
                    @update:model-value="validate"
                />
                <ZInput
                    v-model="title"
                    name="page_url"
                    type="text"
                    :required="false"
                    :label="t.pageTitle"
                />
            </div>
            <horizontal-separator />
            <div class="actions-container">
                <ZButton
                    variety="phoenix"
                    wide="mobile-only"
                    @click="cancelModal()"
                >
                    {{ t.cancel }}
                </ZButton>
                <ZButton
                    variety="optimus"
                    wide="mobile-only"
                    :disabled="!isValid"
                    @click="saveWebsite()"
                >
                    {{ t.save }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, ref, computed, ComputedRef } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import { urlValidator } from '@ts/Util/url';
import Modal from '@components/Core/Modal.vue'
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ZInput from '@components/Core/ZInput.vue';
import { get } from '@ts/Util/http'
import { csrfToken } from '@ts/Util/security';
import debounce from 'lodash-es/debounce';

const translationDefaults = {
    instructions: 'Add a link to another webpage you want visitors to see.',
    title: 'Additional Webpage',
    pageLink: 'Page link',
    pageTitle: 'Page title',
    save: 'Save',
    cancel: 'Cancel',
}

export default defineComponent({
    name: 'WebsiteModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        websiteTitle: {
            type: String,
            required: false,
            default: null
        },
        websiteUrl: {
            type: String,
            required: false,
            default: null
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
        websiteTitleScraperAction: {
            type: String,
            required: false,
            default: ''
        }
    },
    emits: ['update:website'],
    setup(props, context) {
        const show = ref(props.showModal)
        const title = ref(props.websiteTitle)
        const url = ref(props.websiteUrl)

        const attachHttp = (value: string) => {
            if (value && !value.match(/^http[s]?:\/\//)) {
                value = "http://" + value;
            }
            return value;
        };

        const debouncedWebsiteTitleScrap = debounce(async (websiteUrl: string) => {
            const request = await get(props.websiteTitleScraperAction, csrfToken(), {
                website_url: attachHttp(websiteUrl)
            })
            const resp = await request.json()
            if (!request.ok || !resp.success) {
                throw Error('Error while getting the website title')
            }

            title.value = resp.website_title
        }, 500);

        const cancelModal = () => {
            title.value = props.websiteTitle;
            url.value = props.websiteUrl;
            props.handleClosedModal();
        };

        const saveWebsite = () => {
            context.emit('update:website', {
                page_url: attachHttp(url.value),
                title: title.value,
            })
        }

        const internalError = ref(<string>props.error)

        const validate = async () => {
            if (!urlValidator(attachHttp(url.value)) || !!title.value) {
                return;
            }

            debouncedWebsiteTitleScrap(url.value);
        }

        const isValid: ComputedRef = computed<boolean>(() => {
            if (!urlValidator(attachHttp(url.value)) && title.value) {
                return false;
            }
            return true;
        });

        if (!title.value && urlValidator(attachHttp(url.value))) {
            debouncedWebsiteTitleScrap(url.value);
        }

        return {
            show,
            isValid,
            cancelModal,
            title,
            url,
            saveWebsite,
            validate,
            internalError
        }
    },
})
</script>

<style scoped>

:deep(.modal) {
    width: 47rem;
}
.modal-headline {
    font-size: 1.875rem;
    color: var(--zumba-gray-800);
    text-transform: none;
}

.theme-zumba .instructor-website-modal {
    padding-left: 1rem;
    padding-right: 1rem;
}

.instructions {
    font-size: 0.875rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
}

.buttons {
    display: block;
}

.buttons :deep(.z-button.cyclops) {
    margin-right: 1rem;
}

.instructor-website-modal {
    max-width: 47.25rem;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.actions-container > div:first-child {
    order: 2;
    margin-top: 1rem;
}

.actions-container > div:last-child {
    order: 1;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

@media all and (max-width: 48rem) {
    .instructor-website-modal {
        text-align: center;
    }

    .main-container {
        display: block;
    }

    .main-container > div {
        margin-top: 1rem;
    }

    .main-container > div:first-child {
        margin-top: 0;
    }

    :deep(.zInput__label) {
        text-align: left;
    }

    .modal-headline {
        margin-top: 0;
    }
}

@media all and (min-width: 65rem) {

    :deep(.z-button) {
        width: 14.18rem;
        max-width: none;
    }

    .main-container {
        display: flex;
    }

    .main-container > div {
        flex-basis: 50%;
        margin-left: 1rem;
    }

    .main-container > div:first-child {
        margin-left: 0;
    }

    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }
    .actions-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions-container > div:first-child {
        margin-top: 0;
        order: 1;
    }

    .actions-container > div:last-child {
        order: 2;
    }

    .theme-zumba .instructor-website-modal {
        padding-right: 1rem;
    }
}

</style>