<template>
    <div
        class="top-nav"
        :class="{
            'sticky': state.isSticky,
            'top-nav__redesign' : enableRedesign
        }"
    >
        <div class="top-nav__container">
            <a
                v-for="l in visibleLinks"
                :key="l"
                class="f-inter z-b3"
                :class="{
                    'active': l === active,
                    'top-nav-tabs__redesign': enableRedesign,
                }"
                @click.prevent="scrollTo(l)"
            >
                {{ t[l] }}
            </a>
        </div>
    </div>
</template>
<script setup lang="ts">
import { PropType, ComputedRef, Ref, ref, computed, reactive, onMounted } from 'vue'

const props = defineProps({
    triggerElement: {
        type: String,
        required: true,
    },
    links: {
        type: Object,
        required: true,
    },
    enableRedesign: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object,
        default: () => ({
            title: 'Reviews',
            showMore: 'Show More',
            details: 'Details',
            liveClasses: 'Live Classes',
            onDemands: 'On Demand',
            classPacks: 'Class Packs',
            about: 'About',
            instagram: 'Instagram',
            photos: 'Photos',
            ratings: 'Ratings',
        })
    }
})

const active: Ref<string | null> = ref(null);
const visibleLinks: Ref<Array<string>> = ref([]);
const state = reactive({
    isSticky: false
});

onMounted(() => {
    addStickyObserver();
    viewerChecked();
    window.addEventListener('scroll', viewerChecked, {passive: true})
})

const scrollTo = (link) => {
    const element = document.getElementById(props.links[link]);
    if (!element) {
        return;
    }

    window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth"
    });
};

const addStickyObserver = () => {
    const navTrigger = document.getElementById(props.triggerElement);
    const observerOptions = { threshold: 0.1 };
    const toggleIsSticky = (entries) => {
        state.isSticky = entries[0].intersectionRatio < 0.1;
        if (state.isSticky && visibleLinks.value.length === 0) {
            visibleLinks.value = Object.keys(props.links).filter((link) => {
                const element = document.getElementById(props.links[link]);
                return element && element.textContent?.trim() !== '';
            });
        }
    }
    const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
    if (!navTrigger) return;
    observer.observe(navTrigger);
}

const viewerChecked = () => {
    const visibleSections = visibleLinks.value.filter((link) => {
        const element = document.getElementById(props.links[link]);
        if (!element || element.innerHTML.trim() === '') return false;
        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        return ((rect.top >= 2 && rect.top <= windowHeight) || (rect.bottom >= 2 && rect.bottom <= windowHeight));
    });

    if (visibleSections.length === 0) {
        active.value = '';
        return;
    } else if (visibleSections.length === 1) {
        active.value = visibleSections[0];
        scrollToActiveLink();
        return;
    }

    const desiredPosition = window.innerHeight / 2;
    let activeLink = null;
    let minDistance = Infinity;

    for (const link of visibleSections) {
        const elBounds = document.getElementById(props.links[link]).getBoundingClientRect();

        const distanceToTop = Math.abs(elBounds.top - desiredPosition);
        const distanceToBottom = Math.abs(elBounds.bottom - desiredPosition);
        const distance = Math.min(distanceToTop, distanceToBottom);

        if (elBounds.top <= desiredPosition && desiredPosition <= elBounds.bottom) {
            activeLink = link;
            break;
        } else if (distance < minDistance) {
            minDistance = distance;
            activeLink = link;
        }
    }
    active.value = activeLink;
    scrollToActiveLink();
};

const scrollToActiveLink = () => {
    const activeLink = document.querySelector('.top-nav__container .active');
    if (activeLink) {
        const container = document.querySelector('.top-nav__container');
        const containerWidth = container.clientWidth;
        const activeLinkWidth = activeLink.offsetWidth;
        const activeLinkOffsetLeft = activeLink.offsetLeft;
        const scrollLeft = activeLinkOffsetLeft - containerWidth / 2 + activeLinkWidth / 2;
        container.scrollLeft = scrollLeft;
    }
};

</script>
<style scoped>
.top-nav {
  background: var(--zumba-white);
  border-bottom: 1px solid var(--zumba-gray-200);
  display: none;
}

.top-nav.sticky {
  display: block;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.top-nav__container {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  overflow: scroll;
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1.75rem;
}

.theme-strong .top-nav__container {
  height: 3rem;
  padding-top: .5rem;
}

.top-nav__container a {
  border-bottom: 3px solid var(--zumba-white);
  color: var(--zumba-gray-800);
  cursor: pointer;
  padding: 0.5rem 0;
  white-space: nowrap;
}

.theme-strong .top-nav__container a {
  padding: 0.65rem 0;
}

.top-nav__container a.active {
  font-weight: 700;
  color: var(--zumba-plum);
  border-color: var(--zumba-plum);
}

.theme-strong .top-nav__container a.active {
  color: var(--strong-razzmatazz);
  border-color: var(--strong-razzmatazz);
}

.top-nav__redesign {
    background: var(--zumba-gray-100);
}

.top-nav__container .top-nav-tabs__redesign {
    font-weight: 700;
    border-bottom: 3px solid var(--zumba-gray-100);
}

@media only screen and (min-width: 48rem) {

  .top-nav.sticky {
    top: 82px;
  }

  .top-nav__container {
    margin: 0 auto;
    max-width: 1040px;
    overflow: hidden;
    justify-content: center;
  }

  .top-nav__container a:first-child {
    margin-left: 0;
  }
}
</style>
