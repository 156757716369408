<template>
    <section class="credentials">
        <div class="credentials-item">
            <p class="headline z-b4 f-inter">
                {{ t.licenses }}
            </p> 
            <ProfileBadges
                :badge-items="license"
            />
        </div>
        <div class="credentials-item">
            <p class="headline z-b4 f-inter">
                {{ t.education }}
            </p> 
            <ProfileBadges
                :badge-items="education"
                :enable-noom-badge="enableNoomBadge"
            />
        </div>
        <div class="credentials-item">
            <p class="headline z-b4 f-inter">
                {{ t.milestone }}
            </p> 
            <ProfileBadges
                :badge-items="milestone"
            />
        </div>
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { default as ProfileBadges, BadgeData } from "@modules/InstructorPage/ProfileBadges.vue"
import {validateTranslations} from "@ts/Util/i18n"

const translationDefaults = {
    licenseToTeach: 'License to Teach',
    licenses: 'licenses',
    education: 'Education',
    milestone: 'Milestone',
    profileImageAlt: 'Instructor picture'
};

export default defineComponent({
    name: 'ProfileCredentials',
    components: {
        ProfileBadges
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        license: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        education: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        milestone: {
            type: Array as PropType<BadgeData[]>,
            required: true,
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    },
});
</script>

<style scoped>

.headline {
    color: var(--zumba-gray-400);
}
.credentials {
    margin-bottom: 1.5rem;
}

.credentials-item {
    margin-bottom: 1em;
}

</style>
