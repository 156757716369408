<template>
    <div>
        <Modal
            :custom-styles="{ modal: { borderRadius: '0.25rem', maxWidth: '37.5rem' } }"
            :show="show"
            :show-close-button="true"
            :mobile-drawer="true"
            @closed="$emit('close')"
        >
            <h4 class="share-modal__title">
                {{ simplified ? t.shareLink : t.shareableModalTitle }}
            </h4>
            <div
                class="share-modal__content"
                :class="{ 'simplified': simplified }"
            >
                <div
                    v-if="!simplified"
                    class="share-modal__content_image"
                >
                    <img :src="shareData?.previewImageURL">
                </div>
                <CopyTextInput
                    v-if="shareData"
                    class="copy-input"
                    :copied-t="t.copyText"
                    :copy-text="shareData.url"
                    @copied="$emit('share', 'copy_text')"
                />
                <div
                    v-if="!simplified"
                    class="share-modal__content_actions"
                >
                    <ShareStrip
                        v-if="shareData"
                        :t="t"
                        :share-type="shareType"
                        :fb-app-id="fbAppId"
                        :share-data="shareData"
                        @share="handleShared"
                    />
                </div>
            </div>
        </Modal>
        <QRCodeModal
            v-if="shareData && shareData.qrSRC"
            :share-type="shareType"
            :t="t"
            :show="showQRModal"
            :class-data="classData"
            :instructor-data="instructorData"
            :qr-src="shareData.qrSRC"
            @close="showQRModal = false"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, PropType, ref, Ref } from 'vue'
import { validator } from '@ts/Util/i18n'
import Modal from '@components/Core/Modal.vue'
import CopyTextInput from '@components/Core/CopyTextInput.vue'
import ShareStrip from "@components/Sharing/ShareStrip.vue";
import { ShareableInfo } from '@ts/Sharing/sharing'
import QRCodeModal, { QRCodeShareType, ClassData, InstructorData } from '@components/Sharing/QRCodeModal.vue'

const localT = {
    shareableModalTitle: 'Share link or download',
    shareLink: 'Share link',
};

export default defineComponent({
    name: 'ShareModal',
    components: {
        Modal,
        CopyTextInput,
        QRCodeModal,
        ShareStrip,
    },
    props: {
        t: {
            type: Object,
            required: true,
            validate: validator(localT)
        },
        show: {
            type: Boolean,
            default: false,
        },
        shareData: {
            type: Object as PropType<ShareableInfo|null>,
            default: null
        },
        classData: {
            type: Object as PropType<ClassData>,
            default: (() => null),
        },
        instructorData: {
            type: Object as PropType<InstructorData>,
            default: (() => null),
        },
        shareType: {
            type: String as PropType<QRCodeShareType>,
            required: true
        },
        fbAppId: {
            type: String,
            default: '',
        },
        simplified: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['close', 'share'],
    setup(props, ctx) {
        const showQRModal: Ref<boolean> = ref(false);

        const handleShared = (type) => {
            if (type === 'qr') {
                ctx.emit('close');
                showQRModal.value = true;
            }
        };

        return {
            showQRModal,
            handleShared
        }
    }
});
</script>
<style scoped>
.share-modal__title,
.share-modal__content_image{
    margin-bottom: 2rem
}

.share-modal__content_actions {
    margin-top: 2rem;
}

@media all and (min-width: 48rem) {
    .share-modal__content.simplified {
        min-width: 34rem;
    }
}
</style>
