<template>
    <Modal
        :show="showModal"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        @closed="() => $emit('close')"
    >
        <div class="my-page-url-confirm-modal">
            <h3 class="modal-title">
                {{ t.confirmNewUrl }}
            </h3>
            <p class="instructions">
                {{ t.instructions }}
            </p>
            <div class="main-container">
                <!-- eslint-disable vue/no-v-html -->
                <p v-html="urlText" />
                <!--eslint-enable-->
                <HorizontalSeparator />
                <p class="url-copy">
                    {{ confirmationText }}
                </p>
            </div>
            <horizontal-separator />
            <div class="actions-container">
                <ZButton
                    variety="phoenix"
                    wide="mobile-only"
                    @click.prevent="$emit('back')"
                >
                    {{ t.back }}
                </ZButton>
                <ZButton
                    variety="optimus"
                    wide="mobile-only"
                    :disabled="disable"
                    @click.prevent="$emit('confirm', shortname)"
                >
                    {{ t.confirm }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>
  
<script lang="ts">
import { defineComponent, ref, ComputedRef, computed } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import Modal from '@components/Core/Modal.vue'
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import { interpolate } from '@ts/Util/text';

const translationDefaults = {
    confirmNewUrl: "Confirm new URL",
    back: "Back",
    confirm: "Confirm",
    uponConfirmation: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more times.",
    uponConfirmationOne: "Upon confirmation, your URL will be changed and you can modify your instructor page URL {0} more time.",
    uponConfirmationNoMore: "Upon confirmation, your URL will be changed. You will not be able to edit this URL once it is changed.",
}
  
export default defineComponent({
    name: 'MyPageURLConfirmModal',
    components: {
        Modal,
        ZButton,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        shortname: {
            type: String,
            required: true
        },
        requestsLeft: {
            type: Number,
            default: 2
        },
        disable: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
        baseUrl: {
            type: String,
            reuired: true,
            default: ''
        }
    },
    emits: [
        'close',
        'confirm',
        'back'
    ],
    setup(props, context) {
        const confirmationText: ComputedRef<string> = computed(() => {
            if (props.requestsLeft == 1) {
                return interpolate(props.t.uponConfirmationOne, [props.requestsLeft])
            } else if (props.requestsLeft == 0) {
                return (props.t.uponConfirmationNoMore)
            }
            return interpolate(props.t.uponConfirmation, [props.requestsLeft])
        })
        const urlText = computed(() => interpolate(props.t.baseUrl, [`<strong>${props.shortname}</strong>`]))
        return {
            urlText,
            confirmationText,
            showModal: computed(() => {
                return props.shortname?.trim().length > 0
            })
        }
    },
})
</script>
  
<style scoped>

:deep(.modal) {
    width: 47rem;
}
.modal-title {
    color: var(--zumba-gray-800);
    text-transform: none;
}

.instructions {
    font-size: 0.875rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
}

.main-container {
    display: block;
}

.buttons {
    display: block;
}

.buttons :deep(.z-button.cyclops) {
    margin-right: 1rem;
}

.my-page-url-confirm-modal {
    max-width: 47.25rem;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.actions-container > div:first-child {
    order: 2;
    margin-top: 1rem;
}

.actions-container > div:last-child {
    order: 1;
}

.mobile-only {
    display: block;
}

.desktop-only { 
    display: none;
}

@media all and (max-width: 48rem) {
    .my-page-url-confirm-modal {
        text-align: center;
    }

    :deep(.zInput__label) {
        text-align: left;
    }

    .modal-title {
        margin-top: 0;
    }
}

@media all and (min-width: 65rem) {

    :deep(.z-button) {
        width: 14.18rem;
        max-width: none;
    }

    .mobile-only {
        display: none;
    }
    
    .desktop-only { 
        display: block;
    }
    .actions-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions-container > div:first-child {
        margin-top: 0;
        order: 1;
    }
    
    .actions-container > div:last-child {
        order: 2;
    }

    .theme-zumba .my-page-url-confirm-modal {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

</style>