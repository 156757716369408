<template>
    <Modal
        :show="showModal"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="$emit('cancel')"
    >
        <div class="my-page-url-modal">
            <h3 class="modal-headline z-h4 f-inter">
                {{ t.title }}
            </h3>
            <div class="instructions">
                <p class="instructions-description z-b4 f-inter">
                    {{ t.description }}
                </p>
                <p class="instructions-requirements-header z-b4 f-inter">
                    {{ t.urlEditRequirementsHeader }}
                    <ul>
                        <li class="z-b4 f-inter">
                            {{ t.urlEditRequirements1 }}
                        </li>
                        <li class="z-b4 f-inter">
                            {{ t.urlEditRequirements2 }}
                        </li>
                    </ul>
                </p>
            </div>
            <div class="main-container">
                <ZInput
                    v-model="formData.shortname"
                    class="shortname-input"
                    name="shortname"
                    type="text"
                    :required="true"
                    :label="t.shortname"
                    :error="errorMessage"
                    :maxlength="40"
                    @update:model-value="validate"
                />
                <div class="url-preview">
                    <!-- eslint-disable vue/no-v-html -->
                    <p
                        class="z-b4 f-inter"
                        v-html="urlText"
                    />
                    <!--eslint-enable-->
                </div>
            </div>
            <horizontal-separator />
            <div class="actions-container">
                <ZButton
                    variety="phoenix"
                    wide="mobile-only"
                    @click.prevent="$emit('cancel')"
                >
                    {{ t.cancel }}
                </ZButton>
                <ZButton
                    variety="optimus"
                    wide="mobile-only"
                    :disabled="!state.isValid || formData.shortname === ''"
                    @click="confirm()"
                >
                    {{ t.save }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, reactive } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import Modal from '@components/Core/Modal.vue'
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ZInput from '@components/Core/ZInput.vue';
import { interpolate } from '@ts/Util/text';

const translationDefaults = {
    title: 'My Page URL',
    shortname: 'Usename',
    save: 'Save',
    cancel: 'Cancel',
    baseUrl: 'https://zumba.com/p/{0}',
    description: 'Enter your preferred username to customize the URL of this instructor page.',
    urlEditRequirementsHeader: 'Please be sure that your username:',
    urlEditRequirements1: 'begins and ends with a letter or number',
    urlEditRequirements2: 'is unique to you',
    errorCustomURL: 'The custom URL must be between 4-40 characters, and can only contain letters and numbers.',
    errorCustomURLTaken: "This custom URL is already taken please select another"
}

export default defineComponent({
    name: 'MyPageURLModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        shortname: {
            type: String,
            default: '',
        },
        shortnameTaken: {
            type: Boolean,
            default: false
        },
        disable: {
            type: Boolean,
            default: false
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    emits: ['confirm', 'cancel'],
    setup(props, context) {
        const show = ref(props.showModal)
        const formData = reactive({
            shortname: props.shortname as string
        })
        const state = reactive({
            isValid: true as boolean
        })
        const validate = () => {
            state.isValid = formData.shortname.match(/^[a-z\d]{4,40}$/gi) !== null
        }

        const urlText = computed(() => interpolate(
            props.t.baseUrl,
            [`<strong>${formData.shortname}</strong>`]
        ))

        const confirm = () => {
            validate()

            if (state.isValid) {
                context.emit('confirm', formData.shortname)
            }
        }

        const errorMessage = computed(() : string => {
            if (!state.isValid) {
                return  props.t.errorCustomURL
            } else if (props.shortnameTaken && props.shortname === formData.shortname) {
                return props.t.errorCustomURLTaken
            }

            return ''
        })

        return {
            show,
            state,
            formData,
            urlText,
            confirm,
            validate,
            errorMessage
        }
    },
})
</script>

<style scoped>

.my-page-url-modal, .instructions p, .url-preview p {
    font-family: 'Inter';
}

:deep(.modal) {
    width: 47rem;
}
.modal-headline {
    font-size: 1.875rem;
    color: var(--zumba-gray-800);
    text-transform: none;
}

.instructions {
    font-size: 0.875rem;
    color: var( --zumba-gray-500);
    margin-top: 0.688rem;
}

.main-container {
    display: block;
}

.buttons {
    display: block;
}

.buttons :deep(.z-button.cyclops) {
    margin-right: 1rem;
}

.my-page-url-modal {
    max-width: 47.25rem;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.actions-container > div:first-child {
    order: 2;
    margin-top: 1rem;
}

.actions-container > div:last-child {
    order: 1;
}

.mobile-only {
    display: block;
}

.desktop-only {
    display: none;
}

.url-preview {
    margin-top: 1rem;
    color: var(--zumba-gray-500);
}

.instructions-requirements-header {
    text-align: left;
}

@media all and (max-width: 48rem) {
    .my-page-url-modal {
        text-align: center;
    }

    :deep(.zInput__label) {
        text-align: left;
    }

    .modal-headline {
        margin-top: 0;
    }
}

@media all and (min-width: 65rem) {

    :deep(.z-button) {
        width: 14.18rem;
        max-width: none;
    }

    .shortname-input  {
        width: 50%;
    }

    .mobile-only {
        display: none;
    }

    .desktop-only {
        display: block;
    }
    .actions-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions-container > div:first-child {
        margin-top: 0;
        order: 1;
    }

    .actions-container > div:last-child {
        order: 2;
    }

    .theme-zumba .my-page-url-modal {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

</style>
