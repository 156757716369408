<template>
    <Modal
        :show="show"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="emit('closed')"
    >
        <div class="instructor-contact-modal">
            <h3 class="modal-headline f-inter">
                {{ t.contact }}
            </h3>
            <p class="instructions">
                {{ t.modalInstructions }}
            </p>
            <div class="main-container">
                <ZInput
                    v-model="emailValueRef"
                    class="contact-input"
                    :label="t.inputEmail"
                    type="email"
                    name="email"
                    required
                />
                <DialCodeInput
                    v-model="phoneValueRef"
                    class="contact-input"
                    :label="t.inputTextMessage"
                    :dial-codes="formattedDialCodes"
                    :saving="state.saving"
                    @valid="state.phoneError = false"
                    @invalid="state.phoneError = true"
                />
                <DialCodeInput
                    v-model="whatsappValueRef"
                    class="contact-input"
                    label="WhatsApp"
                    :dial-codes="formattedDialCodes"
                    :saving="state.saving"
                    @valid="state.whatsappError = false"
                    @invalid="state.whatsappError = true"
                />
            </div>
            <div class="actions-container">
                <ZButton
                    class="actions-container-button"
                    wide="mobile-only"
                    :variety="buttonThemed"
                    :disabled="state.saving"
                    @click="doCancel"
                >
                    {{ t.cancel }}
                </ZButton>
                <ZButton
                    class="actions-container-button"
                    wide="mobile-only"
                    variety="plum"
                    mode="dark"
                    :disabled="state.saving"
                    @click.prevent="doSave"
                >
                    {{ t.save }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import { Ref, ref, PropType, computed, reactive } from 'vue'
import { csrfToken } from '@ts/Util/security'
import { post } from '@ts/Util/http'
import Modal from '@components/Core/Modal.vue'
import ZButton from '@components/Core/ZButton.vue'
import ZInput from '@components/Core/ZInput.vue';
import DialCodeInput, { DialCodeItems } from '@components/Core/DialCodeInput.vue'
import {theme, ThemeType} from "@ts/Util/theme";

const emit = defineEmits(['closed', 'save'])

const props = defineProps({
    show: {
        type: Boolean,
        default: () => false
    },
    emailValue: {
        type: String,
        default: () => ''
    },
    whatsappValue: {
        type: String,
        default: () => ''
    },
    phoneValue: {
        type: String,
        default: () => ''
    },
    dialCodes: {
        type: Object as PropType<DialCodeItems>,
        required: true,
    },
    action: {
        type: String,
        default: '',
    },
    t: {
        type: Object,
        default: () => ({
            contact: 'Contact',
            modalInstruction: 'Edit how students contact you. Please respond to inquiries within 24 hours.',
            inputEmail: 'Email',
            inputTextMessage: 'Text Message',
            inputPhoneErrorMessage: 'Please enter a valid phone number',
            inputDialCodeMessage: 'Please select a dial code'
        })
    }
})

const emailValueRef: Ref<string> = ref(props.emailValue);
const whatsappValueRef: Ref<string> = ref(props.whatsappValue || '');
const phoneValueRef: Ref<string> = ref(props.phoneValue || '');
const state = reactive({
    saving: false,
    whatsappError: false,
    phoneError: false,
});

const doCancel = () => {
    emailValueRef.value = props.emailValue;
    whatsappValueRef.value = props.whatsappValue;
    phoneValueRef.value = props.phoneValue;
    emit('closed')
};

const formattedDialCodes = computed(() => {
    const formattedDialCodes = {};
    for (const [code, country] of Object.entries(props.dialCodes)) {
        formattedDialCodes[`+${code}`] = country;
    }
    return formattedDialCodes;
});

const buttonThemed = theme.value === ThemeType.Zumba ? 'plum' : 'phoenix';

const isFormValid = (): boolean => {
    if (phoneValueRef.value && state.phoneError) {
        return false;
    }

    if (whatsappValueRef.value && state.whatsappError) {
        return false;
    }

    return true
};

const doSave = async () => {
    state.saving = true;

    if (!isFormValid()) {
        state.saving = false;
        return;
    }

    try {
        const response = await post(props.action, csrfToken(), {
            email: emailValueRef.value,
            whatsapp: whatsappValueRef.value,
            phone: phoneValueRef.value
        });

        if (response.ok) {
            window.location.reload();
        }
    } catch (e) {
        console.error(e);
    } finally {
        state.saving = false;
    }
};

</script>

<style scoped>

:deep(.modal) {
    width: 33rem;
}
.modal-headline {
    font-size: 1.875rem;
    color: var(--zumba-gray-800);
    text-transform: none;
}

.theme-zumba .instructor-contact-modal {
    padding-left: 1rem;
    padding-right: 1rem;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.instructor-contact-modal {
    margin: 0 auto;
}

.instructions {
    font-size: 0.875rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
}
.actions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-input {
    margin-bottom: 1rem;
}
.contact-action-container {
    flex-direction: row;
    justify-content: space-between;
}

.actions-container-button {
    margin-bottom: 1rem;
}

@media only screen and (min-width: 48rem) {
    .actions-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .actions-container-button {
        margin-right: 1rem;
    }
}
</style>