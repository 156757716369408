<template>
    <section
        v-if="editMode || totalClasses > 0"
        class="instructor-section"
        :class="{ 'instructor-section--redesign': enableRedesign }"
    >
        <div 
            class="instructor-section__header"
            :class="{ 'instructor-section__header--redesign': enableRedesign && !editMode,
                      'instructor-section__header--redesign-edit': enableRedesign && editMode }"
        >
            <h4
                v-if="enableRedesign"
                class="instructor-section__title--redesign z-h1"
            >
                {{ t.title }}
            </h4>
            <h5 
                v-else
                class="instructor-section__title f-inter"
            >
                {{ t.title }}
            </h5>
            <a
                v-if="editMode"
                :href="liveClassesAction"
                class="edit-icon"
            >
                <EditIcon />
            </a>
            <div
                v-if="enableRedesign && editMode" 
                class="break--redesign"
            />
            <div
                v-if="(!editMode || state.classes?.length) && !hideFilters"
                class="instructor-section__filters"
            >
                <span
                    class="class-list__filter-trigger"
                >
                    <div
                        v-if="dateFilter"
                        class="class-list__filter-count z-b5"
                    >
                        1
                    </div>
                    <DatePicker
                        v-model="dateFilter"
                        :min="minDateAllowed"
                        class="class-list__filter-datepicker"
                    >
                        <template #icon>
                            <CalendarIcon class="class-list__filter-icon" />
                        </template>
                    </DatePicker>
                </span>
                <a
                    class="class-list__filter-trigger"
                    @click.prevent="showFilters = true"
                >
                    <div
                        v-if="filterCount > 0"
                        class="class-list__filter-count z-b5"
                    >
                        {{ filterCount }}
                    </div>
                    <FilterIcon class="class-list__filter-icon" />
                </a>
            </div>
        </div>
        <div
            v-if="state.classes?.length"
        >
            <div class="class-list">
                <ClassElement
                    v-for="classItem in state.classes"
                    :key="classItem.id"
                    :class-item="classItem"
                    :specialties="specialties"
                    :t="t"
                    :enable-redesign="enableRedesign"
                    @no-direct-payment-modal:open="$emit('no-direct-payment-modal:open', $event)"
                />
            </div>
        </div>
        <div
            v-else-if="!state.classes?.length && filterApplied && !state.loading"
        >
            <div class="no-classes-posted f-inter">
                {{ t.noClassesAvailable }}
                <a
                    class="z-b3 f-inter"
                    @click.prevent="clearFilters()"
                >
                    {{ t.viewFullSchedule }}
                </a>
            </div>
        </div>
        <div
            v-else-if="!state.loading && !filterApplied"
        >
            <div class="no-classes-posted f-inter">
                {{ t.noClassesPosted }}
                <a
                    class="z-b3 f-inter"
                    :href="liveClassesAction"
                >
                    {{ t.add }}
                </a>
            </div>
        </div>
        <div
            v-if="hasMoreClasses && !state.loading"
            :class="{ 'class-list__show-more--redesign': enableRedesign, 'class-list__show-more': !enableRedesign }"
        >
            <ZButton
                v-if="enableRedesign"
                variety="optimus"
                wide="never"
                @click.prevent="showMoreClasses()"
            >
                {{ t.showMore }}
            </ZButton>
            <a
                v-else
                class="f-inter"
                @click.prevent="showMoreClasses()"
            >
                {{ t.showMore }}
            </a>
        </div>
        <div
            v-if="state.loading"
        >
            <loading />
        </div>

        <ClassFilterModal
            :t="t"
            :show="showFilters"
            :specialties="specialties"
            :available-filters="availableFilters"
            mobile-drawer
            @apply="(filters) => selectedFilters = filters"
            @closed="showFilters = false"
        />
    </section>
</template>

<script setup lang="ts">
import { ComputedRef, PropType, Ref, computed, ref, reactive, onMounted, watch } from 'vue'
import { validateTranslations } from '@ts/Util/i18n'
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg'
import FilterIcon from '@bx-icons/regular/bx-filter.svg'
import EditIcon from '@bx-icons/regular/bx-pencil.svg'
import { ClassData, default as ClassElement } from '@components/ClassListing/ClassElement.vue'
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue'
import DatePicker from "@components/ClassListing/DatePicker.vue";
import Loading from '@components/Util/Loading.vue'
import { csrfToken } from '@ts/Util/security'
import { get } from '@ts/Util/http'
import ZButton from "@components/Core/ZButton.vue"

interface Filters {
    durations:  string[];
    specialties:  string[];
    locations:  string[];
}

const props = defineProps({
    classes: {
        type: Array as PropType<ClassData[]>,
        required: true,
    },
    totalClasses: {
        type: Number,
        default: 0
    },
    specialties: {
        type: Object,
        default: () => {}
    },
    editMode: {
        type: Boolean,
        default: () => false
    },
    liveClassesAction: {
        type: String,
        default: ''
    },
    hideFilters: {
        type: Boolean,
        default: false
    },
    moreClassesAction: {
        type: String,
        default: ''
    },
    enableRedesign: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object,
        required: true,
        validator: (value : Object) => validateTranslations(value, {
            title: 'Live Classes',
            showMore: 'Show More',
            noClassesPosted: 'No classes posted.',
            add: 'add',
            noClassesAvailable: 'There are no classes available',
            viewFullSchedule: 'Click here to view full schedule'
        })
    }
})

const emit = defineEmits(['no-direct-payment-modal:open'])

const showFilters: Ref<boolean> = ref(false)
const dateFilter: Ref<String> = ref('');
const selectedFilters: Ref<Filters> = ref({
    durations: [],
    specialties: [],
    locations: [],
});

const minDateAllowed = computed((): string => {
    return new Date().toISOString().split('T')[0]
})

const availableFilters: Ref<Filters> = ref({
    durations: ['underThirty', 'thirtySixty', 'overSixty'],
    specialties: [...Object.keys(props.specialties)],
    locations: ['inPerson', 'virtual']
});

const filterCount: ComputedRef<number> = computed(() => {
    let { durations, specialties, locations } = selectedFilters.value;
    return durations.length + specialties.length + locations.length;
})

const hasMoreClasses: ComputedRef<boolean> = computed(() => {
    return state.classes.length < state.total;
});

const filterApplied: ComputedRef<boolean> = computed(() => {
    return filterCount.value > 0 || dateFilter.value.length > 0;
});

const noClassesPosted: ComputedRef<boolean> = computed(() => {
    return !state.classes?.length && state.firstLoading;
});

const state = reactive({
    firstLoading: false,
    loading: true,
    classes: [] as ClassData[],
    page: 1,
    total: 0,
})

watch(() => dateFilter.value, () => {
    state.classes = [];
    state.page = 0;
    search();
})

watch(() => selectedFilters.value, () => {
    state.classes = [];
    state.page = 0;
    search();
})

const search = async () => {
    try {
        state.loading = true
        state.page++

        const payload = {
            page: state.page,
            dateFrom: dateFilter.value ?? '',
            platformTypes: selectedFilters.value.locations,
            fitnessSpecialties: selectedFilters.value.specialties,
            durations: selectedFilters.value.durations.map(r => `${r.min}-${r.max}`).join(',') ?? ''
        }

        const result = await get(`${props.moreClassesAction}`, csrfToken(), payload)
        if (!result.ok) {
            console.error(result)
            throw new Error('There was an error getting instructor schedule classes.')
        }
        const results = await result.json()
        state.classes = state.classes.concat(results.classes ?? [])
        state.total = results.total
    } catch (error) {
        // TODO: Handle error
        console.error(error)
    } finally {
        state.loading = false
    }
}

const clearFilters = () => {
    state.classes = [];
    state.page = 0;
    dateFilter.value = '';
    selectedFilters.value = {
        durations: [],
        specialties: [],
        locations: [],
    };
};

const showMoreClasses = () => {
    search();
};

onMounted(() => {
    state.classes = [...props.classes]
    state.total = props.totalClasses
    state.loading = false
})

</script>
<style scoped>
    .instructor-section__title {
        margin: 0;
    }

    .theme-strong .instructor-section__title {
        font-family: var(--strong-font-default);
    }
    .theme-strong .instructor-section__title--redesign {
        font-family: var(--strong-font-uniform);
    }

    .instructor-section--redesign {
        padding: 0 1rem;
    }

    .instructor-section__header {
        display: flex;
        align-items: center;
    }

    .instructor-section__filters {
        display: flex;
        margin-left: auto;
    }

    .class-list {
        margin-top: 1.5rem;
    }

    .class-list__show-more {
        margin-top: 1.5rem;
        text-align: center;
    }

    .class-list__show-more a {
        color: var(--zumba-plum);
        display: block;
    }

    .class-list__show-more--redesign {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
    }

    .theme-strong .class-list__show-more a {
        color: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__show-more a {
        color: var(--zumba-plum);
    }

    .class-list__filter-trigger {
        border-radius: .125rem;
        border: 1px solid;
        height: 2.5rem;
        margin-left: 0.5rem;
        padding: 0.625rem;
        position: relative;
        width: 2.5rem;
    }

    .theme-strong .class-list__filter-trigger {
        border-color: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-trigger {
        border-color: var(--zumba-plum);
    }

    .class-list__filter-datepicker {
        left: 0;
        position: absolute;
        top: 0;
    }

    .class-list__filter-count {
        background: var(--zumba-plum);
        border-radius: 100%;
        color: var(--zumba-white);
        display: block;
        font-weight: 500;
        height: 1rem;
        line-height: 1rem;
        position: absolute;
        right: -0.5rem;
        text-align: center;
        top: -0.5rem;
        width: 1rem;
    }

    .theme-strong .class-list__filter-count {
        background: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-count {
        background: var(--zumba-plum);
    }

    .class-list__filter-icon {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
    }

    .theme-strong .class-list__filter-icon {
        fill: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-icon {
        fill: var(--zumba-plum);
    }

    .no-classes-posted {
        text-align: center;
        margin: 1.5em 0;
    }
    .edit-icon {
        align-items: center;
        display: flex;
        margin: -0.2rem 0 0 0.5rem;
    }

    .edit-icon svg {
        width: 1.125rem;
        height: 1.125rem;
    }

    @media screen and (min-width: 48em) {
        .instructor-section--redesign {
            padding: 1.75rem 2rem 0;
        }
        .instructor-section__header--redesign {
            flex-direction: column;
        }
        .instructor-section__header--redesign-edit {
            flex-wrap: wrap;
            justify-content: center;
        }
        .break--redesign {
            flex-basis: 100%;
            height: 0;
        }
        .theme-strong .instructor-section__title--redesign {
            font-size: 3.125rem;
        }
    }

    @media screen and (max-width: 48em) {
        .instructor-section__title--redesign{
            font-size: 1.875rem;
            margin-bottom: 0;
        }

        .theme-strong .instructor-section__title--redesign {
            font-size: 2.25rem
        }

        .theme-zumba .instructor-section__title--redesign{
            font-weight: 700;
        }
    }
</style>
