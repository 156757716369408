<script setup lang="ts">
import { PropType, computed, reactive, watch } from 'vue'
import ToggleSwitch from "@components/Core/ToggleSwitch.vue"
import ZButton from "@components/Core/ZButton.vue"

const props = defineProps({
    t: {
        type: Object as PropType<TranslationKeys>,
        default: (): TranslationKeys => translationDefaults,
    },
    show: {
        type: Boolean,
        default: () => false
    },
    edit: {
        type: Boolean,
        default: () => false
    },
    loading: {
        type: Boolean,
        default: () => false
    },
    noomCoachingUrl: {
        type: String,
        default: ''
    },
})

const emit = defineEmits([
    'visibility-changed',
])

const noomLink = computed(() => props.noomCoachingUrl)

const state = reactive({
    toggled: props.show,
    hasError: false,
})
watch (() => props.show, (newVal) => {
    state.toggled = newVal
})

watch(() => state.toggled, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        emit('visibility-changed', newVal)
    }
})

</script>
<script lang="ts">
const translationDefaults = {
    subtitle: "I’m a Noom Mindset Coach!",
    description: "Round out your workout with Noom’s psychology tools to help achieve your wellness goals.",
    buttonLabel: 'GET 10% OFF',
    hide: 'Hide NOOM banner',
    show: 'Show NOOM banner',
    bannerHidden: 'NOOM Banner hidden. Click on "Show NOOM banner" to display it!',
}

type TranslationKeys = typeof translationDefaults
</script>
<template>
    <div class="noom-banner">
        <div 
            v-if="edit"
            class="toggle"
        >
            <toggle-switch
                v-model="state.toggled"
                :disabled="loading"
            >
                <template #label>
                    <span class="toggle-text f-inter">{{ state.toggled ? t.hide : t.show }}</span>
                </template>
            </toggle-switch>
        </div>
        <div 
            v-if="state.toggled" 
            class="noom-banner__content"
        >
            <p class="noom-banner__title f-inter">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="116" 
                    height="26" 
                    viewBox="0 0 116 26" 
                    fill="none"
                >
                    <path 
                        d="M115.413 25.5583H111.23V5.1515C111.114 5.1515 111.172 5.15151 111.027 5.09261C110.707 5.68155 110.388 6.2116 110.068 6.77109C108.267 9.89248 106.553 13.0139 104.694 16.1353C103.039 19.0505 99.1752 19.0505 97.4613 16.1353C95.5442 12.7488 93.627 9.30354 91.6808 5.91713C91.4775 5.59321 91.3613 5.32819 91.0418 5.06317V25.6172H86.8008C86.8008 25.3522 86.8008 25.0872 86.8008 24.8516C86.8008 18.1671 86.8008 11.5121 86.8008 4.82759C86.8008 2.73685 87.9627 1.05836 89.7637 0.587211C91.6227 0.145506 93.4237 0.587213 94.5856 2.67795C96.5609 6.24105 98.6232 9.89248 100.686 13.485C100.802 13.6911 100.947 13.9267 101.121 14.2506C101.877 12.8961 102.603 11.6593 103.3 10.4225C104.723 7.89008 106.176 5.35763 107.599 2.73685C108.499 1.11726 109.894 0.322189 111.782 0.528318C113.583 0.646106 114.658 1.64731 115.297 3.26689C115.355 3.53192 115.5 3.91473 115.5 4.12086V25.5878H115.413V25.5583Z" 
                        fill="white" 
                    />
                    <path 
                        d="M0.5 5.44596C0.5 4.47421 0.703335 3.44356 1.19715 2.5307C1.31334 2.26568 1.45858 1.94176 1.72001 1.76508C3.63716 -0.384558 6.91956 -0.00174479 8.31385 2.5307C11.5962 8.24343 14.7915 13.9856 18.0739 19.6983C18.3353 20.14 18.5968 20.6112 18.9744 20.994V0.439959H23.1573C23.1573 0.704983 23.2154 0.970008 23.2154 1.20558C23.2154 7.89007 23.1573 14.604 23.2154 21.2885C23.2154 23.497 21.6758 25.0577 20.1363 25.4405C18.2772 25.8822 16.4182 25.1166 15.4015 23.2909C12.0029 17.372 8.66242 11.4532 5.32193 5.5343C5.20574 5.32817 5.0605 5.15149 4.88621 4.82757V25.4994H0.52905C0.500002 18.8444 0.5 12.1599 0.5 5.44596Z" 
                        fill="white" 
                    />
                    <path 
                        d="M38.9882 26C38.233 25.7939 37.4487 25.6172 36.7515 25.4111C29.8963 23.4676 25.8586 15.8408 28.1534 9.0385C29.8963 4.0325 33.4111 0.91111 38.6106 0.204381C46.1921 -0.914607 51.8273 4.23863 53.1054 10.6581C54.3835 17.4309 49.9102 24.1743 43.2873 25.6172C42.7063 25.735 42.0673 25.8822 41.4863 26C40.6439 26 39.8016 26 38.9882 26ZM31.9877 12.9255C31.8715 17.4898 35.3863 21.7007 40.3825 21.7007C45.1173 21.7007 48.8354 17.8726 48.8354 12.9255C48.8354 8.0373 45.1173 4.20918 40.4406 4.15028C35.5896 4.15028 31.8715 8.39066 31.9877 12.9255Z" 
                        fill="white" 
                    />
                    <path 
                        d="M68.4717 26C68.036 25.9411 67.6293 25.7939 67.1936 25.735C61.1517 24.5571 56.7945 19.2272 56.7945 12.9844C56.7945 6.53549 61.5293 0.881656 67.8327 0.116033C75.5303 -0.85572 81.2237 4.4153 82.2694 10.717C82.9666 14.9573 81.8918 18.7266 78.9289 21.9068C76.8084 24.1743 74.1941 25.4699 71.1732 25.8822C71.1151 25.8822 70.9698 25.9411 70.9117 26C70.0693 26 69.256 26 68.4717 26ZM69.6917 4.23862C65.015 4.17973 61.2388 7.94895 61.1517 12.6899C61.0355 17.637 64.6665 21.6713 69.4884 21.7302C74.2232 21.7891 78.0284 18.0198 78.0865 13.1611C78.0865 8.12563 74.4556 4.29751 69.6917 4.23862Z" 
                        fill="white" 
                    />
                </svg>
            </p>
            <p class="noom-banner__description f-inter">
                <b>{{ t.subtitle }}</b><br>
                {{ t.description }}
            </p>
            <z-button
                v-if="noomLink"
                variety="optimus"
                wide="never"
                :href="noomLink"
                target="_blank"
                rel="noopener"
            >
                {{ t.buttonLabel }}
            </z-button>
        </div>
        <div 
            v-else-if="edit"
            class="noom-banner__edit"
        >
            <div class="noom-banner__edit-wrapper">
                <p 
                    class="hidden-text z-b2 f-inter"
                >
                    {{ t.bannerHidden }}
                </p>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    .noom-banner {
        margin: 1.875rem auto;
    }

    .noom-banner__edit {
        background-color: var(--zumba-white);
        padding: 2rem 2rem .5rem;
    }

    .noom-banner__edit-wrapper {
        text-align: center;
    }

    .noom-banner__content {
        display: flex;
        flex-direction: column;
        background-color: var(--noom-orange);
        padding: 1.5rem 3rem;
        align-items: center;
        text-align: center;
    }

    .noom-banner__description {
        color: var(--zumba-white);
    }

    .toggle {
        display: flex;
        justify-content: end;
        margin-bottom: .8rem;
        margin-right: 1rem;
    }

    .toggle-text {
        margin-right: 0.625rem;
        color: var(--zumba-gray-800);
    }

    @media only screen and (min-width: 29rem) {
        .noom-banner__edit-wrapper {
            margin: 0 auto;
            max-width: 1040px;
        }
    }
    @media only screen and (min-width: 48rem) {
        .noom-banner {
            margin: 2.5rem auto;
            max-width: 1010px;
        }

        .toggle {
            margin-right: 0;
        }
    }
</style>
