<template>
    <section class="instructor-about-section">
        <div class="instructor-section--header">
            <h4
                class="instructor-section__title--redesign z-h1"
            >
                {{ t.about }}
            </h4>
            <a
                v-if="editMode"
                class=""
                href="#"
                @click.prevent="showEditModal = true"
            >
                <PencilIcon />
            </a>
        </div>
        <div
            v-if="about !== ''"
            class="instructor-about-header z-b2 f-inter"
        >
            {{ t.bio }}
        </div>
        <div class="instructor-body">
            <p
                v-if="biography"
                class="bio-text z-b3 f-inter"
                :class="{ 'show-overflow': moreText }"
            >
                <span
                    v-if="!moreText && showMoreLink"
                >
                    {{ truncatedBio }}
                </span>
                <span
                    v-else
                >
                    {{ biography }}
                </span>
            </p>
            <a
                v-if="showMoreLink || moreText"
                class="more-text__link z-b3 f-inter"
                @click="toggleMoreText"
            >
                {{ moreText ? 'Read Less' : 'Read More' }}
            </a>
        </div>
        <div
            v-if="about === '' && editMode"
            class="z-b3 f-inter"
        >
            {{ t.noBioPosted }}
            <a
                class="z-b3 f-inter"
                href="#"
                @click.prevent="showEditModal = true"
            >
                {{ t.add }}
            </a>
        </div>
        <HorizontalSeparator
            v-if="about !== '' || editMode"
        />
        <div class="about-me z-b3 f-inter">
            <p
                v-if="memberships.length > 0"
                class="instructor-about-header z-b2 f-inter"
            >
                {{ t.memberSince }}
            </p>
            <div class="membership-details">
                <span
                    v-for="(membership, index) in memberships"
                    :key="index"
                    class="member-type z-b3 f-inter"
                >
                    <span v-if="membership.start_date">
                        {{ membership.membership_type }} {{ t.instructor }}
                        <span class="member-since">
                            {{ sinceYearMemberships[membership.membership_type] }}
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <HorizontalSeparator />
        <div
            v-if="instructorBadges.length > 0"
            class="about-me z-b3 f-inter"
        >
            <p class="instructor-about-header z-b2 f-inter">
                {{ t.achievements }}
            </p>
            <div class="profile-badges-container">
                <ProfileBadges
                    :badge-items="visibleBadges"
                    :enable-noom-badge="enableNoomBadge"
                />
            </div>
            <div class="view-all-link">
                <a
                    v-if="showViewAllAchievements"
                    class="f-inter"
                    @click.prevent="showMore"
                >
                    {{ t.viewAllAchievements }}
                </a>
                <a
                    v-if="showViewLessAchievements"
                    class="f-inter"
                    @click.prevent="showLess"
                >
                    {{ t.viewLessAchievements }}
                </a>
            </div>
        </div>
        <AboutModal
            :t="t"
            :show="showEditModal"
            :action="detailsInstructorAction"
            :about-value="about"
            :licenses-value="licenses"
            :license-badges="licenseBadges"
            @closed="showEditModal = false"
        />
    </section>
    <div class="horizontal-container">
        <HorizontalSeparator />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref, computed } from 'vue';
import {validateTranslations, currentUserLocale} from "@ts/Util/i18n";
import { interpolate } from '@ts/Util/text';
import AboutModal from '@modules/InstructorPage/AboutModal.vue'
import PencilIcon from '@bx-icons/regular/bx-pencil.svg';
import { default as ProfileBadges, BadgeData } from "@modules/InstructorPage/ProfileBadges.vue"
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import { isMobileReactive } from '@ts/Util/responsiveness'

const translationDefaults = {
    about: '',
    bio: '',
    licensesCertifications: '',
    memberSince: '',
    since: '',
    noBioPosted: '',
    add: '',
    achievements: '',
    readMore: '',
    readLess: '',
    viewAllAchievements: '',
    sinceText: '',
    viewLessAchievements: '',
    instructor: ''
}

interface MembershipData  {
    membership_type: string
    start_date: string
}

export default defineComponent({
    name: 'About',
    components: {
        AboutModal,
        PencilIcon,
        ProfileBadges,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            default: (): Object => translationDefaults,
            validator: (value: Object) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        biography: {
            type: String,
            required: false,
            default: ''
        },
        memberships: {
            type: Array as PropType<MembershipData[]>,
            required: false,
            default: () => ([])
        },
        detailsInstructorAction: {
            type: String,
            default: '',
        },
        about: {
            type: String,
            required: false,
            default: ''
        },
        licenses: {
            type: String,
            required: false,
            default: ''
        },
        instructorBadges: {
            type: Array as PropType<BadgeData[]>,
            required: false,
            default: () => ([])
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
        licenseBadges: {
            type: Array as PropType<BadgeData[]>,
            required: false,
            default: () => ([])
        }
    },
    setup(props) {
        const showEditModal: Ref<Boolean> = ref(false)

        const formatDate = (dateTime: string) => {
            if (!dateTime) {
                return null;
            }
            const date: Date = new Date(dateTime);
            return date.toLocaleDateString(currentUserLocale(), { month: "short", year: "numeric" });
        };

        const sinceYearMemberships = computed(() => {
            const data = {};
            props.memberships.forEach((membership) => {
                data[membership.membership_type] = interpolate(props.t.sinceText, [formatDate(membership.start_date)]);
            });
            return data;
        });

        const moreText = ref(false);

        const isMobile: Ref<boolean> = isMobileReactive()

        const maxBioLength = computed(() => isMobile.value ? 250 : 370);

        const showMoreLink = computed(() => props.biography.length > maxBioLength.value && !moreText.value);

        const truncatedBio = computed(() => {
            if (moreText.value) {
                return props.biography;
            }
            return props.biography.length > maxBioLength.value
                ? props.biography.slice(0, maxBioLength.value) + '...'
                : props.biography;
        });

        const toggleMoreText = () => {
            moreText.value = !moreText.value;
        };

        const initialVisibleRecords = 8;

        const visibleRecords = ref(initialVisibleRecords);

        const visibleBadges = computed(() => props.instructorBadges.slice(0, visibleRecords.value));

        const showMore = () => {
            visibleRecords.value += props.instructorBadges.length - initialVisibleRecords;
        };

        const showLess = () => {
            visibleRecords.value = initialVisibleRecords;
        };

        const showViewAllAchievements = computed(() => visibleRecords.value < props.instructorBadges.length);
        const showViewLessAchievements = computed(() => visibleRecords.value > initialVisibleRecords);

        return {
            formatDate,
            showEditModal,
            toggleMoreText,
            showMoreLink,
            moreText,
            visibleBadges,
            showViewAllAchievements,
            showMore,
            truncatedBio,
            showLess,
            showViewLessAchievements,
            sinceYearMemberships
        };
    },
})


</script>

<style scoped>
.instructor-about-section {
    max-width: 1040px;
    padding: 2rem 2rem 3rem;
    margin: 0 auto;
}
.instructor-section--header {
    margin-bottom: 1.5rem;
    display: flex;
}
.theme-zumba .instructor-section__title--redesign  {
    font-size: 1.875rem;
    font-weight: 700;
    margin-top: 1.6rem;
}

.theme-strong .instructor-section__title--redesign  {
    font-family: var(--strong-font-uniform);
    font-size: 2.25rem;
    line-height: 2.25rem;
    margin: 1.8rem 0 .85rem 0;
}

.theme-strong .instructor-section--title {
    font-family: var(--strong-font-default);
}

.instructor-section--header a {
    align-items: center;
    display: flex;
    margin-left: 0.5rem;
}

.instructor-section--header a svg {
    width: 1.125rem;
    height: 1.125rem;
    margin-top: 1.25rem;
}

.instructor-about-header {
    font-weight: 700;
    margin-bottom: 1.5em;
    text-transform: uppercase;
}

.member-type {
    display: block;
    font-weight: 700;
    line-height: 1.7;
}

.member-since {
    font-weight: 400;
 }

.instructor-body {
    width: 100%;
}

.bio-text {
    position: relative;
    margin-bottom: 1rem;
    white-space: pre-wrap;
    overflow: hidden;
    line-height: 1.5em;

    &.show-overflow {
        overflow: visible;
    }

    .ellipsis {
        display: inline-block;
    }

    .less-text {
        position: initial;
        padding-left: 0;
    }
}

.more-text__link {
    display: block;
    margin-top: 0.5rem;
}

.horizontal-container {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 2rem;
}

.hr:last-child {
    margin: 0;
}

.hr::before {
    border-top: 1px dashed var(--zumba-gray-200);
    background-color: transparent;
    border-spacing: 2px;
}

.about-me :deep(.badges-container) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
}

.about-me :deep(.badge-icon) {
    display: inline-flex;
}

.about-me :deep(.badge-name) {
    display: inline-flex;
    margin-left: .5rem;
    text-transform: capitalize;
    max-width: 6rem;
    text-align: left;
}

.instructor-section {
    margin: 0 auto;
}

.no-margin-bottom {
    margin-bottom: 0;
}

.profile-badges-container {
    margin-bottom: 1.5rem;
}

.view-all-link {
    position: relative;
    text-align: center
}

@media only screen and (min-width: 29rem) {
    .details-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .theme-zumba .instructor-section__title--redesign  {
        font-size: 3.125rem;
        font-weight: 800;
    }

    .theme-strong .instructor-section__title--redesign {
        font-family: var(--strong-font-uniform);
        font-size: 3.125rem;
        font-weight: 500;
        margin: 2.96rem 0 .7rem 0;
    }

    .about-me :deep(.badges-container) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.75rem;
    }

    .about-me :deep(.badge-name) {
        max-width: 10rem;
    }

    .view-all-link {
        position: relative;
        text-align: left
    }


}

</style>
