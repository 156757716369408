<template>
    <div
        v-if="!enableRedesign"
        class="redesign-margin-top"
    />
    <section class="instructor-section">
        <div class="instructor-section--header">
            <h5 class="instructor-section--title f-inter">
                {{ t.about }}
            </h5>
            <a
                v-if="editMode"
                class=""
                href="#"
                @click.prevent="showEditModal = true"
            >
                <PencilIcon />
            </a>
        </div>
        <div
            v-if="instructor.about !== ''"
            class="instructor-bio z-b4 f-inter"
        >
            {{ t.bio }}
        </div>
        <div class="about-me z-b3 f-inter">
            {{ instructor.about }}
        </div>
        <div
            v-if="instructor.about === '' && editMode"
            class="z-b3 f-inter"
        >
            {{ t.noBioPosted }}
            <a
                class="z-b3 f-inter"
                href="#"
                @click.prevent="showEditModal = true"
            >
                {{ t.add }}
            </a>
        </div>
        <hr class="separator">
        <div class="details-item ">
            <div class="details-item-wrapper">
                <p class="license-to-teach z-b4 f-inter">
                    {{ t.licensesCertifications }}
                </p>
                <!-- eslint-disable vue/no-v-html-->
                <span
                    class="member-license-details z-b3 f-inter"
                    v-html="memberLicenses"
                />
                <span
                    class="license-details z-b3 f-inter"
                    v-html="licenses"
                />
                <!--eslint-enable-->
            </div>
            <div class="details-item-wrapper">
                <p
                    v-if="memberships.length > 0"
                    class="member-since z-b4 f-inter"
                >
                    {{ t.memberSince }}
                </p>
                <div class="membership-details">
                    <span
                        v-for="(membership, index) in memberships"
                        :key="index"
                        class="z-b3 f-inter"
                    >
                        {{ membership.membership_type }} {{ t.since }} {{ formatDate(membership.start_date) }}
                    </span>
                </div>
            </div>
        </div>
        <AboutModal
            :t="t"
            :show="showEditModal"
            :action="detailsInstructorAction"
            :about-value="instructor.about"
            :licenses-value="licenses"
            @closed="showEditModal = false"
        />
    </section>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, Ref } from 'vue';
import {validateTranslations} from "@ts/Util/i18n";
import { Instructor } from '@modules/InstructorPage/InstructorCover.vue'
import AboutModal from '@modules/InstructorPage/AboutModal.vue'
import PencilIcon from '@bx-icons/regular/bx-pencil.svg';

const translationDefaults = {
    about: '',
    bio: '',
    licensesCertifications: '',
    memberSince: '',
    since: '',
    noBioPosted: '',
    add: ''
}

interface MembershipData  {
    membership_type: string[]
    start_date: string
}

export default defineComponent({
    name: 'About',
    components: {
        AboutModal,
        PencilIcon
    },
    props: {
        t: {
            type: Object,
            default: (): Object => translationDefaults,
            validator: (value: Object) => validateTranslations(value, translationDefaults)
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        memberships: {
            type: Array as PropType<MembershipData[]>,
            required: true
        },
        detailsInstructorAction: {
            type: String,
            default: '',
        },
        instructor: {
            type: Object as PropType<Instructor>,
            required: true
        },
        licenses: {
            type: String,
            required: true,
        },
        memberLicenses: {
            type: String,
            required: true,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const showCredentials: Ref<Boolean> = ref(false)
        const showEditModal: Ref<Boolean> = ref(false)

        const formatDate = (dateTime: string) => {
            if (!dateTime) {
                return null;
            }
            const date: Date = new Date(dateTime);
            return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
        };
        return {
            formatDate,
            showCredentials,
            showEditModal
        };
    },
})


</script>

<style scoped>
.instructor-section--header {
    align-items: center;
    display: flex;
}

.instructor-section--header h5 {
    margin-bottom: 0;
}

.theme-strong .instructor-section--title {
    font-family: var(--strong-font-default);
}

.instructor-section--header a {
    align-items: center;
    display: flex;
    margin-left: 0.5rem;
}

.instructor-section--header a svg {
    width: 1.125rem;
    height: 1.125rem;
}

.instructor-bio {
    color: var(--zumba-gray-400);
    margin: 2em 0 1em 0;
}

.about-me {
    max-width: 59.438rem;
    margin-bottom: 2em;
}

.details-item {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: -1.85rem;
}
.details-item-wrapper {
    max-width: 	21.375rem;
}

.details-item-wrapper span {
    content: ", "
}

.license-to-teach {
    color: var(--zumba-gray-400);
    margin: 2em 0 1em 0;
}

.member-since {
    color: var(--zumba-gray-400);
    margin: 2em 0 1em 0;
}

.membership-details span + span::before {
    content: ", ";
 }
.view-credentials-details {
    margin-top: 0.85em;
}
.member-license-details {
    display: block;
    margin-bottom: .5rem;
}
.license-details sup {
  font-size: 1rem;
}

.redesign-margin-top {
    margin-top: 2rem;
}

@media only screen and (min-width: 29rem) {
    .details-item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

</style>
