<template>
    <div class="item-container">
        <div class="review-card">
            <div class="review__content">
                <Rating
                    :score="review.rating"
                />
                <div class="review__student-avatar">
                    <img
                        v-if="review.profile_photo_url"
                        :src="review.profile_photo_url + '?w=40&h=40&fit=crop&auto=format,compress 2x'"
                    >
                    <div
                        v-else
                        class="default-student-avatar"
                    >
                        <span class="student-initials">
                            {{ studentInitials }}
                        </span>
                    </div>
                </div>
                <div class="review-comments">
                    <h4
                        v-if="review.class_title && theme === ThemeType.Zumba"
                        class="review__class-name z-h4"
                    >
                        {{ review.class_title }}
                    </h4>
                    <h5
                        v-else
                        class="review__class-name z-h5"
                    >
                        {{ review.class_title }}
                    </h5>
                </div>
                <p
                    v-if="review.comment && theme === ThemeType.Zumba"
                    class="review__details f-inter z-b3"
                >
                    {{ review.comment }}
                </p>
                <p
                    v-else
                    class="review__details z-b3"
                >
                    {{ review.comment }}
                </p>
                <div class="review__footer">
                    <div
                        v-if="theme === ThemeType.Zumba"
                        class="review__student-name f-inter z-b2"
                    >
                        {{ review.display_name }}
                    </div>
                    <div
                        v-else
                        class="review__student-name z-b3"
                    >
                        {{ review.display_name }}
                    </div>
                    <div
                        v-if="theme === ThemeType.Zumba"
                        class="review__date f-inter z-b3"
                    >
                        {{ reviewDateText }} {{ t.at }} {{ reviewTimeText }}
                    </div>
                    <div
                        v-else
                        class="review__date z-b3"
                    >
                        {{ reviewDateText }} {{ t.at }} {{ reviewTimeText }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, Ref, computed, ref } from 'vue'
import Rating from './Rating';
import { currentUserLocale } from "@ts/Util/i18n";
import { theme, ThemeType } from '@ts/Util/theme';

export interface ReviewData {
    pid: string,
    display_name: string,
    first_name: string,
    last_name: string,
    profile_photo_url?: string,
    class_title?: string,
    rating?: number,
    comment?: string,
    created: string,
}

const props = defineProps({
    review : {
        type: Object as PropType<ReviewData>,
        required: true
    },
    t: {
        type: Object,
        default: () => ({
            at: 'at',
        })
    }
})

const reviewDateText: Ref<string> = computed(
    (): string => {
        const dateFormatOpts: any = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'}
        return new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(new Date(props.review.created));
    }
)

const reviewTimeText: Ref<string> = computed(
    (): string => {
        const timeFormatOpts: any = { hour: 'numeric', minute: '2-digit' }
        return new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(new Date(props.review.created));
    }
)
const firstName = ref(props.review.first_name)
const lastName = ref(props.review.last_name)

const studentInitials: Ref<string> = computed(() => {
    const initials = `${firstName.value.charAt(0)}${lastName.value.charAt(0)}`
    return initials.toUpperCase()
})

</script>

<style scoped>
.item-container {
    max-width: 20rem;
    display: flex;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 1.5rem;
}

.review-card {
    position: relative;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 14.25rem;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 1rem;
}

.theme-strong .review-card {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 0;
}

.review__content {
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.review__content :deep(svg.rating-star) {
    width: 1.5rem;
    fill: var(--zumba-neon-green);
    margin-right: 0.25rem;
}

.review__content :deep(.rating) {
    margin-bottom: 1rem;
}

.theme-strong .review__content :deep(svg.rating-star) {
    fill: var(--strong-primary-orange);
}

.review__student-avatar {
    margin-bottom: 1rem;
}

.review__student-avatar img {
    border-radius: 100%;
    width: 4rem;
}

.default-student-avatar {
    width: 4rem;
    height: 4rem;
    background: var(--zumba-gray-200);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.student-initials {
    color: var(--zumba-gray-400);
    font-weight: bold;
}

.review__student-name {
    font-weight: 700;
}

.review__class-name {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.6rem;
}

.theme-strong .review__class-name {
    color: var(--zumba-white);
}

.review__details {
    margin-bottom: 1rem;
}

.review-comments {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 1rem;
}

.review__details {
    flex-shrink: 0;
    margin-bottom: 1rem;
}

.review__footer {
    display: inline-grid;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}


@media only screen and (min-width: 29rem) {
    .item-container {
        width: 19.5rem;
    }
}

</style>
