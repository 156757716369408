<template>
    <Modal
        :show="showModal"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="() => handleClosedModal()"
    >
        <div class="instructor-name-modal">
            <h3 class="modal-headline f-inter">
                {{ t.title }}
            </h3>
            <p class="instructions">
                {{ t.instructions }}
            </p>
            <div class="main-container">
                <ZInput
                    v-model="name"
                    name="name"
                    type="text"
                    :required="true"
                    :label="t.yourName"
                    :error="internalError"
                    @update:model-value="validateName"
                />
            </div>
            <horizontal-separator />
            <div class="actions-container">
                <ZButton
                    variety="phoenix"
                    wide="mobile-only"
                    @click="handleClosedModal()"
                >
                    {{ t.cancel }}
                </ZButton>
                <ZButton
                    variety="optimus"
                    wide="mobile-only"
                    :disabled="!nameChanged"
                    @click="saveName()"
                >
                    {{ t.save }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>
  
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import Modal from '@components/Core/Modal.vue'
import ZButton from '@components/Core/ZButton.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ZInput from '@components/Core/ZInput.vue';

const translationDefaults = {
    instructions: 'Choose the name to display on your instructor page',
    title: 'Edit Name',
    yourName: 'Your name',
    save: 'Save',
    cancel: 'Cancel',
}
  
export default defineComponent({
    name: 'InstructorNameModal',
    components: {
        Modal,
        ZButton,
        ZInput,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        instructorName: {
            type: String,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        },
        error: {
            type: String,
            required: false,
            default: ''
        },
    },
    emits: ['update:name'],
    setup(props, context) {
        const show = ref(props.showModal)
        const nameChanged = ref(false)
        const name = ref(props.instructorName)

        const saveName = () => {
            nameChanged.value = false
            context.emit('update:name', name)
        }

        const internalError = ref(<string>props.error)
        
        const validateName = () => {
            if (name.value === '') {
                internalError.value = props.error
            } else {
                internalError.value = ''
            }

            nameChanged.value = name.value !== '' && name.value !== props.instructorName
        }
  
        return {
            show,
            name,
            nameChanged,
            saveName,
            validateName,
            internalError
        }
    },
})
</script>
  
<style scoped>

:deep(.modal) {
    width: 47rem;
}
.modal-headline {
    font-size: 1.875rem;
    color: var(--zumba-gray-800);
    text-transform: none;
}

.theme-zumba .instructor-name-modal {
    padding-left: 1rem;
    padding-right: 1rem;
}

.instructions {
    font-size: 0.875rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
}

.main-container {
    display: block;
}

.buttons {
    display: block;
}

.buttons :deep(.z-button.cyclops) {
    margin-right: 1rem;
}

.instructor-name-modal {
    max-width: 47.25rem;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.actions-container > div:first-child {
    order: 2;
    margin-top: 1rem;
}

.actions-container > div:last-child {
    order: 1;
}

.mobile-only {
    display: block;
}

.desktop-only { 
    display: none;
}

@media all and (max-width: 48rem) {
    .instructor-name-modal {
        text-align: center;
    }

    :deep(.zInput__label) {
        text-align: left;
    }

    .modal-headline {
        margin-top: 0;
    }
}

@media all and (min-width: 65rem) {

    :deep(.z-button) {
        width: 14.18rem;
        max-width: none;
    }

    .mobile-only {
        display: none;
    }
    
    .desktop-only { 
        display: block;
    }
    .actions-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions-container > div:first-child {
        margin-top: 0;
        order: 1;
    }
    
    .actions-container > div:last-child {
        order: 2;
    }

    .theme-zumba .instructor-name-modal {
        padding-right: 1rem;
    }
}

</style>