<template>
    <Modal
        :show="show"
        close-when-click-on-background
        close-when-type-esc
        :show-close-button="false"
        :custom-styles="{
            modal: {
                'width': '100%',
                'max-width': '47.25rem'
            }
        }"
        mobile-drawer
        @closed="$emit('closed')"
    >
        <div class="socials_header">
            <h4 v-if="isMobile">
                {{ t.socialLinks }}
            </h4>
            <h3 v-else>
                {{ t.socialLinks }}
            </h3>
            <p class="f-inter z-b4">
                {{ t.socialLinksDetails }}
            </p>
        </div>
        <div class="socials_form">
            <div
                v-for="network in networks"
                :key="network"
            >
                <div
                    class="socials_form-item"
                    :class="{
                        'withValue': !!values[network]
                    }"
                >
                    <z-input
                        v-model="values[network]"
                        type="text"
                        class="f-inter"
                        :name="network"
                        :label="t[network]"
                        :maxlength="50"
                        :error="state.errorSocialNetwork[network] ? t.errorInvalidSocialLink : ''"
                        @update:model-value="validateSocialNetwork(network)"
                    >
                        <template #right-slot>
                            <component :is="network + '-icon'" />
                        </template>
                    </z-input>
                </div>
            </div>
        </div>
        <HorizontalSeparator />
        <div class="socials__buttons">
            <z-button
                class="socials__buttons-button"
                wide="always"
                variety="plum"
                @click.prevent="doCancel"
            >
                {{ t.cancel }}
            </z-button>
            <z-button
                class="socials__buttons-button"
                wide="always"
                variety="plum"
                mode="dark"
                :disabled="state.isSaving"
                @click.prevent="doSave"
            >
                {{ t.save }}
            </z-button>
        </div>
    </Modal>
</template>
<script lang="ts">
import { Ref, ref, defineComponent, reactive } from 'vue'
import { isMobileReactive } from '@ts/Util/responsiveness'
import { csrfToken } from '@ts/Util/security'
import { post } from '@ts/Util/http'
import { validateTranslations } from '@ts/Util/i18n'
import Modal from '@components/Core/Modal.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ZButton from '@components/Core/ZButton.vue'
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg'
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg'
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg'
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg'
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg'
import ZInput from '@components/Core/ZInput.vue'
import { validateEmail } from '@ts/Util/email';

const translationDefaults = {
    socialLinks: 'Social Links',
    socialLinksDetails: 'Link directly to your social accounts by adding your usernames',
    facebook: 'Facebook',
    instagram: 'Instagram',
    spotify: 'Spotify',
    tiktok: 'Tiktok',
    linkedin: 'Linkedin',
    cancel: 'Cancel',
    save: 'Save',
    errorInvalidSocialLink: 'Invalid format',
}

export default defineComponent({
    name: 'SocialModal',
    components: {
        Modal,
        HorizontalSeparator,
        ZButton,
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon,
        ZInput,
    },
    props: {
        show: {
            type: Boolean,
            default: () => false
        },
        action: {
            type: String,
            required: true,
        },
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        socialLinks: {
            type: Object,
            required: true,
        }
    },
    emits: ['closed', 'save'],
    setup(props, ctx) {
        const networks: Ref<Array<string>> = ref([
            'facebook', 'instagram', 'spotify', 'tiktok', 'linkedin',
        ]);

        // Minimal validation pattern for social media profiles (url characters)
        const urlPattern = /^(https?:\/\/)?[a-zA-Z0-9-._~:/?#[@\]!$&'()*+,;=]+$/;

        const state = reactive({
            errorSocialNetwork: {} as Record<string, boolean>,
            isSaving: false,
        })

        networks.value.forEach(network => {
            state.errorSocialNetwork[network] = false;
        });

        const values: Ref<Object> = ref(props.socialLinks);
        const isMobile: Ref<boolean> = isMobileReactive()

        const doCancel = () => {
            values.value = props.socialLinks;
            ctx.emit('closed')
        };

        const doSave = async () => {
            if (!isFormValid()) return false;
            state.isSaving = true;
            const response = await post(props.action, csrfToken(), values.value);
            if (response.ok) {
                window.location.reload();
            } else {
                state.isSaving = false;
            }
        };

        const validateSocialNetwork = (network: string) => {
            const isValid = isValidSocialNetwork(network);
            state.errorSocialNetwork[network] = !isValid;
        }

        const isValidSocialNetwork = (network: string): boolean => {
            const value = values.value[network] || '';
            if (value) {
                //not allow emails
                if (!urlPattern.test(value) || validateEmail(value)) {
                    return false
                }
            }
            return true;
        }

        const isFormValid = (): boolean => {
            networks.value.forEach(network => {
                const isValid = isValidSocialNetwork(network);
                state.errorSocialNetwork[network] = !isValid;
            });

            if (Object.values(state.errorSocialNetwork).some(error => error)) {
                return false;
            }

            return true
        }

        return {
            doCancel,
            doSave,
            isMobile,
            networks,
            values,
            state,
            validateSocialNetwork,
        }
    }
});


</script>
<style scoped>
.socials_header {
    text-align: center;
}

.socials_header h3 {
    text-transform: none;
}

.socials_header p {
    color: var(--zumba-gray-600);
}

.socials_form-item :deep(.zInput__right-slot svg) {
    fill: var(--zumba-gray-300);
}

.socials_form-item.withValue :deep(.zInput__right-slot svg) {
    fill: var(--zumba-gray-800);
}

.socials__buttons-button:first-child {
    display: none;
}


@media only screen and (min-width: 48rem) {

    .socials_header {
        text-align: left;
    }

    .socials_form {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 1rem;
    }

    .socials__buttons {
        display: flex;
    }

    .socials__buttons-button:first-child {
        display: block;
        margin-right: auto;
    }
    .socials_form-item {
        margin-top: 0;
    }
}
</style>
