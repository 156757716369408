<template>
    <div 
        class="tag f-inter"
        :class="classes"
    >
        <slot />
        <x-icon
            v-if="showCancel"
            class="tag_close_icon"
            @click="$emit('cancel:clicked')"
        />
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import XIcon from "@bx-icons/regular/bx-x.svg"

const props = defineProps({
    showCancel: {
        type: Boolean,
        default: true
    },
    variety: {
        type: String,
        default: 'default',
        validator: (value: string) => {
            // You can add more variety types here
            const allowedVarieties = [
                'default',
                'filled',
                'featured',
                'disabled',
                'information',
                'success',  
                'warning',
                'error',
                'small',
            ];
            return value.split(' ').every(elem => allowedVarieties.includes(elem));
        },
    },
})

const classes = computed(() => {
    return {
        [props.variety] : true,
    }
})

const emit = defineEmits(['cancel:clicked'])

</script>

<style scoped>
.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1px 14px 1px 14px;
    background-color: #F8DEEB;
    color: var(--zumba-plum);
    white-space: nowrap;
    border-radius: 14px;
    height: 28px;
    gap: 4px;
    font-size: .875rem;
}
.tag_close_icon {
    fill: var(--zumba-plum);
    cursor: pointer;
    margin-right: -0.25rem;
    height: 1rem;
}
.filled {
    font-weight: 700;
}
.tag.filled {
    background-color: var(--zumba-plum);
    color: var(--zumba-white);
}
.tag.filled .tag_close_icon {
    fill: var(--zumba-white);
}

.tag.featured {
    background-color: var(--zumba-white);
    color: var(--zumba-gray-800);
}
.tag.featured .tag_close_icon {
    fill: var(--zumba-gray-800);
}

.tag.disabled {
    background-color: #E6E6E7;
    color: var(--zumba-gray-800);
}
.tag.disabled .tag_close_icon {
    fill: var(--zumba-gray-800);
}
.tag.disabled.filled {
    background-color: var(--zumba-gray-800);
    color: var(--zumba-white);
}
.tag.disabled.filled .tag_close_icon {
    fill: var(--zumba-white);
}

.tag.information {
    background-color: #D9D3F7;
    color: var(--zumba-dark-blue);
}
.tag.information .tag_close_icon {
    fill: var(--zumba-dark-blue);
}
.tag.information.filled {
    background-color: var(--zumba-dark-blue);
    color: var(--zumba-white);
}
.tag.information.filled .tag_close_icon {
    fill: var(--zumba-white);
}

.theme-strong .tag.information.filled {
    background-color: var(--strong-primary-blue);
}

.tag.success {
    background-color: #DBECE6;
    color: var(--zumba-dark-green);
}
.tag.success .tag_close_icon {
    fill: var(--zumba-dark-green);
}
.tag.success.filled {
    background-color: var(--zumba-success-green);
    color: var(--zumba-dark-green);
}
.tag.success.filled .tag_close_icon {
    fill: var(--zumba-dark-green);
}

.tag.warning {
    background-color: #F6E6D8;
    color: var(--zumba-gray-800);
}
.tag.warning .tag_close_icon {
    fill: var(--zumba-gray-800);
}
.tag.warning.filled {
    background-color: var(--zumba-orange);
}

.theme-strong .tag.warning.filled {
    background-color: var(--strong-primary-orange);
}

.tag.error {
    background-color: #F1D5D4;
    color: var(--zumba-gray-800);
}
.tag.error .tag_close_icon {
    fill: var(--zumba-gray-800);
}
.tag.error.filled {
    background-color: var(--zumba-error-red);
    color: var(--zumba-white);
}
.tag.error.filled .tag_close_icon {
    fill: var(--zumba-white);
}
.tag.small {
    font-size: .75rem;
    padding: 1px 10px 1px 10px;
    height: 20px;
    border-radius: 10px;
}
</style>
