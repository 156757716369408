<template>
    <label :for="uniqueID">
        <slot name="label" />
        <span
            class="switch"
            :class="{
                disabled: disabled,
                focused: isFocused
            }"
        >
            <input
                :id="uniqueID"
                v-model="isChecked"
                :name="name"
                type="checkbox"
                :disabled="disabled"
                @click="isFocused = false"
                @focus="isFocused = true"
                @blur="isFocused = false"
            >
            <span class="slider" />
        </span>
    </label>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { v4 as uuid } from 'uuid'

const uniqueID = uuid()
const isFocused = ref(false)
const emit = defineEmits(['update:modelValue'])
const isChecked = computed({
    get(): boolean {
        return props.modelValue
    },
    set(isChecked: boolean): void {
        emit('update:modelValue', isChecked);
    }
})

const props = defineProps({
    name: {
        type: String,
        default: `toggle_switch`
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelValue: {
        type: Boolean,
        default: false,
    }
})
</script>
<style scoped>
    label {
        cursor: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        -webkit-tap-highlight-color: transparent;
        margin: 0;
    }
    .switch {
        --switch-container-width: 2.625rem;
        --switch-height: 1.4rem;
        --slider-dot-size: calc(var(--switch-height) * .75);
        --margin: calc(var(--switch-height) * .15);
        --translate: calc(
            var(--switch-container-width) -
            var(--slider-dot-size) -
            calc(var(--margin) * 2)
        );
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        position: relative;
        width: var(--switch-container-width);
        height: var(--switch-height);
        border-radius: 1.5rem;
        -webkit-tap-highlight-color: transparent;
        flex-basis: auto;
        flex-shrink: 0;
        overflow: hidden;
        cursor: pointer;
    }
    .switch.disabled {
        opacity: .8;
        cursor: not-allowed;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: var(--slider-background-color, var(--zumba-gray-200));
        -webkit-transition: .4s;
        transition: .4s;
    }
    .slider:before {
        box-sizing: border-box;
        position: absolute;
        content: "";
        height: var(--slider-dot-size);
        width: var(--slider-dot-size);
        left: var(--margin);
        background-color: var(--zumba-white);
        border: 1px solid var(--zumba-gray-100);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }
    input:checked + .slider{
        background-color: var(--slider-active-color, var(--zumba-plum));
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(var(--translate));
        -ms-transform: translateX(var(--translate));
        transform: translateX(var(--translate));
    }
</style>
