<template>
    <section
        v-if="editMode || sessions?.length"
        class="instructor-section"
    >
        <div class="instructor-section__header">
            <h5 class="instructor-section__title f-inter">
                {{ titleThemed }}
            </h5>
            <a
                v-if="editMode"
                :href="manageSessionsAction"
                class="edit-icon"
            >
                <EditIcon />
            </a>
            <div
                v-if="(!editMode || sessions?.length) && !hideFilters"
                class="instructor-section__filters"
            >
                TBD filters
            </div>
        </div>
        <div
            v-if="visibleSessions?.length"
        >
            <div class="class-list">
                <SessionListElement
                    v-for="session in visibleSessions"
                    :key="session.id"
                    :session-data="session"
                    :t="t"
                />
            </div>
        </div>
        <div
            v-if="filteredClasses.length > visibleRecords"
            class="class-list__show-more"
        >
            <a
                class="f-inter"
                @click.prevent="visibleRecords += pageSize"
            >
                {{ t.showMore }}
            </a>
        </div>
    </section>
</template>

<script lang="ts">
const translationDefaults = {
    strongTitle: 'Sync Labs',
    zumbaTitle: 'Jam Sessions',
    showMore: 'Show More',
}
</script>

<script setup lang="ts">
import { PropType, Ref, computed, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
import SessionListElement, { SessionData } from '@components/SessionsList/SessionListElement.vue';
import { theme, ThemeType } from "@ts/Util/theme";


const props = defineProps({
    t: {
        type: Object,
        required: true,
        validator: (value : Object) => validateTranslations(value, translationDefaults)
    },
    sessions: {
        type: Array as PropType<SessionData[]>,
        required: true,
    },
    editMode: {
        type: Boolean,
        default: () => false
    },
    manageSessionsAction: {
        type: String,
        default: ''
    },
    hideFilters: {
        type: Boolean,
        default: true
    },
    pageSize: {
        type: Number,
        default: 3
    }
});

const visibleRecords: Ref<number> = ref(props.pageSize)

const filteredClasses = computed((): SessionData[] => {
    if (!props.sessions) {
        return [];
    }
    //TBD filters
    return props.sessions
})

const visibleSessions = computed((): SessionData[] => {
    return filteredClasses.value.slice(0, visibleRecords.value);
});

const titleThemed = computed((): string => {
    return theme.value === ThemeType.Zumba ? props.t.zumbaTitle : props.t.strongTitle;
})

</script>
<style scoped>
.instructor-section__title {
    margin: 0;
}

.theme-strong .instructor-section__title {
    font-family: var(--strong-font-default);
}

.instructor-section__header {
    display: flex;
    align-items: center;
}

.instructor-section__filters {
    display: flex;
    margin-left: auto;
}

.class-list {
    margin-top: 1.5rem;
}

.class-list__show-more {
    margin-top: 1.5rem;
    text-align: center;
}

.class-list__show-more a {
    color: var(--zumba-plum);
    display: block;
}

.theme-strong .class-list__show-more a {
    color: var(--strong-razzmatazz);
}

.theme-zumba .class-list__show-more a {
    color: var(--zumba-plum);
}

.class-list__filter-trigger {
    border-radius: .125rem;
    border: 1px solid;
    height: 2.5rem;
    margin-left: 0.5rem;
    padding: 0.625rem;
    position: relative;
    width: 2.5rem;
}

.theme-strong .class-list__filter-trigger {
    border-color: var(--strong-razzmatazz);
}

.theme-zumba .class-list__filter-trigger {
    border-color: var(--zumba-plum);
}

.class-list__filter-datepicker {
    left: 0;
    position: absolute;
    top: 0;
}

.class-list__filter-count {
    background: var(--zumba-plum);
    border-radius: 100%;
    color: var(--zumba-white);
    display: block;
    font-weight: 500;
    height: 1rem;
    line-height: 1rem;
    position: absolute;
    right: -0.5rem;
    text-align: center;
    top: -0.5rem;
    width: 1rem;
}

.theme-strong .class-list__filter-count {
    background: var(--strong-razzmatazz);
}

.theme-zumba .class-list__filter-count {
    background: var(--zumba-plum);
}

.class-list__filter-icon {
    display: block;
    height: 1.25rem;
    width: 1.25rem;
}

.theme-strong .class-list__filter-icon {
    fill: var(--strong-razzmatazz);
}

.theme-zumba .class-list__filter-icon {
    fill: var(--zumba-plum);
}

.no-sessions-posted {
    text-align: center;
    margin: 1.5em 0;
}
.edit-icon {
    align-items: center;
    display: flex;
    margin: -0.2rem 0 0 0.5rem;
}

.edit-icon svg {
    width: 1.125rem;
    height: 1.125rem;
}
</style>
