<template>
    <div class="container">
        <Modal
            :show="show"
            show-close-button
            close-when-click-on-background
            mobile-drawer
            :custom-styles="{
                modal: {
                    'width': '100%',
                    'max-width': '37.375rem'
                }
            }"
            @closed="$emit('close')"
        >
            <div class="qr-modal-body">
                <h4
                    class="qr-modal-title"
                    :class="{ 'qr-modal-instructor-title': shareType === QRCodeShareType.SHARE_INSTRUCTOR }"
                >
                    {{ modalTitle }}
                </h4>
                <p
                    v-if="shareType === QRCodeShareType.SHARE_INSTRUCTOR"
                    class="z-b3 f-inter qr-modal-description"
                >
                    {{ t.scanThisQRCodeInstructor }}
                </p>
                <section class="qr-section">
                    <img
                        class="qr-image"
                        :src="qrSrc"
                    >
                    <div
                        v-if="shareType === QRCodeShareType.SHARE_CLASS"
                        class="details"
                    >
                        <h5 class="class-title">
                            {{ classData.title }}
                        </h5>
                        <p class="z-b3 f-inter">
                            {{ classDate }}
                        </p>
                        <p class="z-b3 qr-instructions f-inter">
                            {{ t.scanThisQRCode }}
                        </p>
                    </div>
                    <div
                        v-if="shareType === QRCodeShareType.SHARE_INSTRUCTOR"
                        class="details"
                    >
                        <h5 class="instructor-title">
                            {{ instructorData.title }}
                        </h5>
                        <p class="z-b3 f-inter">
                            {{ instructorData.location }}
                        </p>
                        <p class="z-b3 f-inter membership-type-label">
                            {{ instructorData.membership_type }}
                        </p>
                        <p>
                            <ul class="instructor-details-list">
                                <li
                                    v-if="instructorData.rating_score"
                                    class="z-b4 f-inter"
                                >
                                    <StartSvg class="rating-star" /> {{ instructorData.rating_score }}
                                </li>
                                <li class="z-b4 f-inter separator">
                                    ·
                                </li>
                                <li
                                    v-if="instructorData.num_reviews"
                                    class="z-b4 f-inter"
                                >
                                    {{ instructorData.num_reviews }} {{ t.ratings }}
                                </li>
                            </ul>
                        </p>
                    </div>
                </section>
                <section class="controls-section">
                    <ZButton @click.prevent="printQRCode()">
                        {{ t.printQRLabel }}
                    </ZButton>
                    <ZButton
                        class="cancel-button"
                        variety="phoenix"
                        @click.prevent="$emit('close')"
                    >
                        {{ t.cancel }}
                    </ZButton>
                </section>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { validator } from '@ts/Util/i18n'
import ZButton from '@components/Core/ZButton.vue'
import Modal from '@components/Core/Modal.vue'
import { currentUserLocale } from '@ts/Util/i18n'
import StartSvg from '@bx-icons/solid/bxs-star.svg'

const localT = {
    shareClass: "Share Class",
    shareInstructor: "Share QR Code",
    printQRLabel: "Print",
    shareQRLabel: "Share",
    downloadQRLabel: "Download",
    cancel: "Cancel",
    scanThisQRCode: "Students can scan this code to sign up for your class or to be marked as attended.",
    scanThisQRCodeInstructor: "This QR code will link students to your instructor page.",
}

export enum QRCodeShareType {
    SHARE_CLASS = 'share-class',
    SHARE_INSTRUCTOR = 'share-instructor',
}

export interface ClassData {
    title: string,
    location: string,
    date: string,
}

export interface InstructorData {
    title: string,
    location: string,
    membership_type: string,
    rating_score: number,
    num_reviews: number,
}

export default defineComponent({
    name: 'QRCodeModal',
    components: {
        ZButton,
        Modal,
        StartSvg,
    },
    props: {
        t: {
            type: Object,
            default: localT,
            validator: validator(localT)
        },
        show: {
            type: Boolean,
            default: false
        },
        shareType: {
            type: String as PropType<QRCodeShareType>,
            required: true
        },
        classData: {
            type: Object as PropType<ClassData>,
            default: (() => null),
        },
        instructorData: {
            type: Object as PropType<InstructorData>,
            default: (() => null),
        },
        qrSrc: {
            type: String,
            required: true
        },
    },
    emits: ['close'],
    setup(props) {
        const printQRCode = (): void => {
            const imgHtml = new Image()
            imgHtml.src = props.qrSrc
            const WindowObject = window.open('#', 'PrintQR', 'width=800,height=800,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes')
            const strHtml = `<html><head></head><body onload='window.print();window.close()'>${scanThisQRCode.value}<br><br><br>${imgHtml.outerHTML}</body></html>`
            if (!WindowObject) {
                return
            }
            WindowObject.document.writeln(strHtml)
            WindowObject.document.close()
            WindowObject.focus()
        }
        const modalTitle = computed(() => {
            return props.shareType === QRCodeShareType.SHARE_CLASS ? props.t.shareClass : props.t.shareInstructor
        })

        const classDate = computed(() => {
            if (!props.classData.date) {
                return '';
            }

            const locale: string = currentUserLocale()
            const classDate = new Date(props.classData.date);
            const day: string = classDate.toLocaleDateString(locale, {
                weekday: 'long'
            })
            const date: string = classDate.toLocaleDateString(locale, {
                month: 'long', day: 'numeric'
            })
            const time: string = classDate.toLocaleTimeString(locale, {
                hour: 'numeric',
                minute: 'numeric',
            })

            return `${day}, ${date} @ ${time}`
        })

        const scanThisQRCode = computed(() => {
            return props.shareType === QRCodeShareType.SHARE_CLASS ? props.t.scanThisQRCode : props.t.scanThisQRCodeInstructor
        })
        return {
            printQRCode,
            QRCodeShareType,
            modalTitle,
            classDate,
            scanThisQRCode
        }
    },
})
</script>
<style scoped lang="scss">
.qr-modal-title {
    margin: 1rem 0 1rem 0;
    text-transform: unset;
    color: var(--zumba-gray-800);
    font-size: 1.5rem;
}

.qr-modal-instructor-title {
    display: flex;
    justify-content: center;
}
.qr-modal-description {
    display: flex;
    justify-content: center;
    font-size: 1rem;
}

.class-title,
.instructor-title {
    color: var(--zumba-gray-800);
    margin: 0;
    font-size: 1.25rem;
}

.qr-section {
    position: relative;
    border: thin solid var(--zumba-gray-200);
    border-radius: .25rem;
    padding: 1rem;
    margin: 1rem auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.qr-image {
    max-width: 14.875rem;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
    padding: 0;
    overflow: hidden;
    text-align: center;
    width: 100%;
    max-width: 16rem;
    margin-top: .25rem;
    margin-bottom: 1.5rem;
    color: var(--zumba-gray-800);

    p {
        margin: 0;

        &:not(.qr-instructions) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .membership-type-label {
        color: var(--zumba-gray-800);
        font-weight: 700;
    }
}

.separator {
    line-height: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 .2rem;
}

ul.instructor-details-list {
    display: inline-flex;
    gap: 0.5rem;
    font-size: .875rem;
    color: #484A55;
    list-style-type: disc;
    margin-left: 0;

    li:first-child {
        margin-left: 0;
        list-style-type: none;
    }

    li {
        display: inline-block;
    }

    li > .rating-star {
        width: 1rem;
        height: 1rem;
        vertical-align: text-top;
    }

    li:last-child {
        font-weight: 700;
    }
}

.theme-zumba .rating-star {
    fill: var(--zumba-plum);
}

.theme-strong .rating-star {
    fill: var(--strong-razzmatazz);
}

.class-location {
    padding-bottom: 1rem;
}

.qr-instructions {
    padding-top: 1rem;
}

.controls-section {
    margin: 1rem auto auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.print-button {
    display: none;
}

@media all and (min-width: 48rem) {
    .qr-modal-title {
        font-size: 1.875rem;
    }

    .class-title,
    .instructor-title {
        font-size: 1.5rem;
    }

    .details {
        text-align: left;
        padding: 0 0 0 2.5rem;
        margin-top: 0;
        margin-bottom: 0;
    }

    .qr-instructions {
        padding-top: .5rem;
    }

    .qr-section {
        flex-direction: row;
        align-items: flex-start;
        padding: 2.5rem;
    }

    .qr-image {
        max-width: 8.5rem;
    }

    .share-button,
    .cancel-button {
        display: none;
    }

    .print-button {
        display: block;
    }
}
</style>
