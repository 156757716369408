<template>
    <Modal
        :show="show"
        close-when-click-on-background
        close-when-type-esc
        :show-close-button="false"
        enable-scroll
        mobile-drawer
        :custom-styles="customModalStyles"
        @closed="emit('closed')"
    >
        <div class="about__header">
            <h3>{{ t.about }}</h3>
            <p class="f-inter z-b4">
                {{ t.aboutDetails }}
            </p>
        </div>
        <div class="about__content">
            <div class="about__form-field text-area">
                <label class="f-inter z-b4">{{ t.instructorBio }}</label>
                <textarea
                    v-model="aboutValueRef"
                    maxlength="{{ ABOUT_MAX_LENGTH }}"
                />
            </div>
            <div class="about__form-counter f-inter z-b5">
                {{ aboutLength }}
            </div>
            <div class="about__form-counter f-inter z-b5">
                <span
                    v-if="isAboutMaxLengthReached"
                    class="warning"
                >
                    {{ t.youHaveReachedTheMaximumLength }}
                </span>
            </div>
            <div class="about__form-field">
                <label class="f-inter z-b5">{{ t.licensesCertifications }}</label>
                <textarea
                    v-model="licensesValueRef"
                    maxlength="{{ LICENSES_MAX_LENGTH }}"
                />
            </div>
            <div
                :class="{ 'max-length-reached': isMaxLengthReached }"
                class="about__form-counter f-inter z-b5"
            >
                {{ licensesLength }}
            </div>
            <div class="about__form-counter f-inter z-b5">
                <span
                    v-if="isMaxLengthReached"
                    class="warning"
                >
                    {{ t.youHaveReachedTheMaximumLength }}
                </span>
            </div>
        </div>
        <HorizontalSeparator />
        <div class="about__buttons">
            <z-button
                class="about__buttons-button"
                wide="always"
                variety="plum"
                @click="doCancel"
            >
                {{ t.cancel }}
            </z-button>
            <z-button
                class="about__buttons-button"
                wide="always"
                variety="plum"
                mode="dark"
                @click="doSave"
            >
                {{ t.save }}
            </z-button>
        </div>
    </Modal>
</template>
<script setup lang="ts">
import { isMobileReactive } from '@ts/Util/responsiveness'
import { interpolate } from "@ts/Util/text";
import { ComputedRef, Ref, computed, ref, PropType, watch } from 'vue'
import { csrfToken } from '@ts/Util/security'
import { post } from '@ts/Util/http'
import Modal from '@components/Core/Modal.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import ZButton from '@components/Core/ZButton.vue'
import { BadgeData } from "@modules/InstructorPage/ProfileBadges.vue";
const emit = defineEmits(['closed', 'save'])

const props = defineProps({
    show: {
        type: Boolean,
        default: () => false
    },
    aboutValue: {
        type: String,
        default: () => ''
    },
    licensesValue: {
        type: String,
        default: () => ''
    },
    licenseBadges: {
        type: Array as PropType<BadgeData[]>,
        required: false,
        default: () => ([])
    },
    action: {
        type: String,
        default: '',
    },
    t: {
        type: Object,
        default: () => ({})
    }
})
const excludedWords = ['Zumba', 'Strong', 'Circl'];

const ABOUT_MAX_LENGTH = 2500;
const LICENSES_MAX_LENGTH = 255;

function defaultLicensesValueFromLicenseBadges() {
    return props.licenseBadges
        .map(badge => badge.badge_name)
        .join(", ")
}

const defaultLicensesValue = props.licensesValue === '' ?
    defaultLicensesValueFromLicenseBadges() : props.licensesValue

const aboutValueRef: Ref<string> = ref(props.aboutValue);
const licensesValueRef: Ref<string> = ref(defaultLicensesValue);

const aboutLength: ComputedRef<string> = computed<string>(() => {
    if (!props.t.maxCharacters) {
        return ''
    }

    return interpolate(props.t.maxCharacters, [ABOUT_MAX_LENGTH - aboutValueRef.value.length]);
})

const licensesLength: ComputedRef<string> = computed<string>(() => {
    if (!props.t.maxCharacters) {
        return ''
    }
    return interpolate(props.t.maxCharacters, [LICENSES_MAX_LENGTH - licensesValueRef.value.length]);
});

const isAboutMaxLengthReached = computed(() => {
    return aboutValueRef.value.length >= ABOUT_MAX_LENGTH
});

const isMaxLengthReached = computed(() => {
    return licensesValueRef.value.length >= LICENSES_MAX_LENGTH;
});

watch(aboutValueRef, (newValue) => {
    if (newValue.length > ABOUT_MAX_LENGTH) {
        aboutValueRef.value = newValue.slice(0, ABOUT_MAX_LENGTH);
    }
})

watch(licensesValueRef, (newValue) => {
    if (newValue.length > LICENSES_MAX_LENGTH) {
        licensesValueRef.value = newValue.slice(0, LICENSES_MAX_LENGTH);
    }
});

const customModalStyles = computed<object>(() => {
    const isMobile: Ref<boolean> = isMobileReactive()

    let modalStyle = {
        border: 0,
        width: isMobile.value ? '100%' : '47.25rem',
        paddingTop: isMobile.value ? '1.25rem' : '2.5rem',
        paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
        paddingLeft: isMobile.value ? '1.25rem' : '2.5rem',
        paddingRight: isMobile.value ? '1.25rem' : '2.5rem',
        bottom: isMobile.value ? '1.25rem': '',
        left: isMobile.value ? 0: '',
        top: isMobile.value ? 'initial': '',
        transform: isMobile.value ? 'none': '',
        borderRadius: isMobile.value ? '0.5rem 0.5rem 0 0': '',
    }

    return {
        modal: modalStyle
    }
})

const doCancel = () => {
    aboutValueRef.value = props.aboutValue;
    licensesValueRef.value = defaultLicensesValue
    emit('closed')
};

const doSave = async () => {
    const response = await post(props.action, csrfToken(), {
        about: aboutValueRef.value,
        licenses: licensesValueRef.value
    });

    if (response.ok) {
        window.location.reload();
    }
};

</script>
<style scoped>
.about__header h3 {
	text-transform: none;
}

.about__header p {
	color: var(--zumba-gray-600);
}

.about__form-field {
	border: 1px solid var(--zumba-gray-200);
	margin-top: 1rem;
	padding: 0.25rem 0.75rem;
}

.about__form-field.textarea {
	padding: 1.125rem 0.75rem;
}

.about__form-field label {
	color: var(--zumba-gray-400);
}

.about__form-field input,
.about__form-field textarea {
	background: transparent;
	border: 0;
	font-size: 0.875rem;
	height: auto;
	outline: none;
	padding: 0;
	resize: none;
	box-shadow: none;
}

.about__form-counter {
	color: var(--zumba-gray-400);
	padding-top: 0.5rem;
	text-align: right;
}

.about__buttons-button:first-child {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.about__buttons {
		display: flex;
	}

	.about__buttons-button {
		min-width: 14.18rem;
	}

	.about__buttons-button:first-child {
		display: block;
		margin-right: auto;
	}
}
</style>
