<template>
    <div class="session-element f-inter">
        <div class="session-element__info">
            <div class="session-element__date z-b2">
                <span>{{ classDateText }}</span>
            </div>
        </div>
        <div class="session-element__details">
            <div class="session-element__address z-b4">
                {{ platformTypeText }} • {{ sessionData.location }}
            </div>
            <a
                class="session-element__title p f-inter"
                :href="sessionData.details_url"
            >
                {{ sessionData.title }}
            </a>
            <div class="session-element__address z-b4">
                {{ t.language }}: {{ sessionData.language }}
            </div>
        </div>
        <div class="session-element__cta">
            <div
                v-if="sessionData.price"
                class="session-element__price"
            >
                {{ priceFormatted }}
            </div>
            <div class="session-element__cta-elements">
                <ZButton
                    class="session-element__book-button"
                    :disabled="ctaDisabled"
                    :variety="buttonThemed"
                    :href="sessionData.register_url || sessionData.details_url"
                >
                    {{ ctaText }}
                </ZButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
const translationDefaults = {
    register: 'Register',
    soldOut: 'Sold Out',
    language: 'Language',
    virtual: 'Virtual',
    inPerson: 'In Person',
    online: 'Online',
    details: 'Details',
}
</script>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n'
import ZButton from "@components/Core/ZButton.vue"
import {theme, ThemeType} from "@ts/Util/theme";
import { validateTranslations } from '@ts/Util/i18n'

export interface SessionData {
    id: string,
    title: string,
    details_url: string,
    language: string,
    platform_type: string,
    location: string,
    date: string,
    status: string,
    price: number,
    price_currency: string,
    register_url: string,
}

const props = defineProps({
    t: {
        type: Object,
        required: true,
        validator: (value : Object) => validateTranslations(value, translationDefaults)
    },
    sessionData : {
        type: Object as PropType<SessionData>,
        required: true
    },
})

const classDateText = computed((): string => {
    const dateFormatOpts: any = { weekday: 'long', month: 'short', day: 'numeric'}
    const classDate = new Date(props.sessionData.date);

    let classDateTextFormatted = "";
    
    try {
        classDateTextFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
    } catch (error) {
        //safari 15 support: https://github.com/w3c/respec/issues/1357
        const classDate = new Date(props.sessionData.date.replace(/-/g, "/"));
        classDateTextFormatted = new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate);
    }
    return classDateTextFormatted;
})

const priceFormatted = computed((): string => {
    return formatCurrencyStr(props.sessionData.price || 0, props.sessionData.price_currency, currentUserLocale(), true)
})

const ctaText = computed((): string => {
    if (props.sessionData.status === 'sold-out' || props.sessionData.status === 'sold_out') {
        return props.t.soldOut;
    } else if (props.sessionData.status === 'info_only') {
        return props.t.details;
    } else {
        return props.t.register;
    }
});

const ctaDisabled = computed(() => {
    return props.sessionData.status === 'sold-out' || props.sessionData.status === 'sold_out' || props.sessionData.status === 'info_only';
});

const platformTypeText = computed((): string => {
    let text = '';
    if (props.sessionData.platform_type === 'virtual') {
        text = props.t.virtual;
    } else if (props.sessionData.platform_type === 'online') {
        text = props.t.online;
    } else {
        text = props.t.inPerson;
    }
    return text;
});

const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';

</script>
<style scoped>
.session-element {
  position: relative;
}

.session-element + .session-element {
  margin-top: 2rem;
}

.session-element__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative
}

.session-element__date {
  color: var(--zumba-gray-800);
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.session-element__date::after {
  content: "";
  background: var(--zumba-gray-200);
  display: block;
  height: 1px;
  position: absolute;
  top: 0.8125rem;
  width: 100%;
}

.session-element__date span {
  background: var(--zumba-white);
  padding-right: 0.5rem;
  position: relative;
  z-index: 2;
}

.session-element__time {
  color: var(--zumba-gray-800);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.session-element__details {
  min-width: 0;
  color: var(--zumba-gray-600);
  padding-right: 2rem;
}

.session-element__details-specialties  span {
  display: inline-block;
  vertical-align: middle;
}

.session-element__details-specialties  span + span::before {
  background: var(--zumba-gray-700);
  border-radius: 100%;
  content: " ";
  display: inline-block;
  height: 3px;
  margin: 0 0.5rem;
  vertical-align: middle;
  width: 3px;
}

.session-element__title {
  display: block;
  margin: 0.45rem 0;
}

.theme-strong .session-element__title {
  color: var(--strong-razzmatazz);
}

.theme-zumba .session-element__title {
  color: var(--zumba-plum);
}

.session-element__address {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

.session-element__price {
  color: var(--zumba-gray-800);
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  right: 0;
  top: 2.25rem;
}

.session-element__book-button {
  margin-top: 1rem;
}

.session-element__book-button:deep(.z-button) {
  font-weight: 400;
}

.session-element__cta-extras {
  color: var(--zumba-gray-500);
  margin-top: 0.25rem;
  text-align: center;
}
.theme-strong .session-element__cta-extras {
  font-family: var(--strong-font-default);
  font-size: 0.75rem;
}

@media only screen and (min-width: 48rem) {
  .session-element {
    border: 1px solid var(--zumba-gray-200);
    border-radius: 2px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 1.5rem 1rem 1.5rem 1.75rem;
  }

  .session-element + .session-element {
    margin-top: 1rem;
  }

  .session-element__details {
    border-left: 1px solid var(--zumba-gray-200);
    border-right: 1px solid var(--zumba-gray-200);
    padding: 0 1.5rem;
  }

  .session-element__date::after {
    display: none;
  }

  .session-element__time {
    color: var(--zumba-gray-600);
    font-weight: 400;
  }

  .session-element__price {
    font-size: 1rem;
    position: relative;
    right: initial;
    top: initial;
  }

  .session-element__cta {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left:  1rem;
  }

  .session-element__cta-elements {
    margin-left: 1.5rem;
    max-width: 9.413rem;
  }

  .session-element__book-button {
    margin-top: 0;
  }
}
</style>
