<template>
    <div
        v-if="Object.keys(socialLinks).length"
        class="social"
    >
        <div class="social-label f-inter">
            {{ t.socialLabel }}
        </div>
        <social-links
            :social-links="socialLinks"
            :size="size"
        />
    </div>
</template>
<script lang="ts">
import { SocialLinksType, default as SocialLinks, SocialLinksSize } from '@components/SocialLinks/SocialLinks.vue';
import { defineComponent, PropType, Ref, ref } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";

const translationDefaults = {
    socialLabel: 'Social',
}

export default defineComponent({
    name: 'Social',
    components: {
        SocialLinks
    },
    props: {
        socialLinks: {
            type: Object as PropType<SocialLinksType>,
            required: true
        },
        size: {
            type: String as PropType<SocialLinksSize>,
            default: 'normal',
        },
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
    },
    emits: ['open-modal'],
    setup() {
        return {
        }
    }
})
</script>
<style scoped>
    .social-label {
        font-size: .875rem;
        margin-bottom: .5rem;
        display: block;
    }
    .theme-strong .social-label {
        color: var(--zumba-gray-400);
    }

    .social {
        text-align: center;
    }

    .social {
        text-align: inherit;
    }

    @media only screen and (max-width: 47.9375rem) {
        .social-label {
            display: none;
        }

    }
</style>
