<template>
    <div
        class="badges-container"
    >
        <div
            v-for="(badgeItem, index) in badgeItems"
            :key="index"
            class="badges-item"
        >
            <div :class="{ 'hide-noom-badge' : !enableNoomBadge && badgeItem.badge_slug === 'noom_certified' }">
                <img
                    class="badge-icon"
                    :src="badgeItem.badge_icon"
                >
                <strong class="badge-name z-b5 f-inter">{{ badgeItem.badge_name }}</strong>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export interface BadgeData  {
    badge_icon: string
    badge_name: string
    badge_slug: string
}

export default defineComponent({
    name: 'ProfileBadges',
    props: {
        badgeItems: {
            type: Array as PropType<BadgeData[]>,
            required: true
        },
        enableNoomBadge: {
            type: Boolean,
            default: false
        },
    }
})
</script>
  
<style scoped>
.badges-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.badge-name {
  display: block;
  text-align: center;
  text-transform: uppercase;
}

.badge-icon {
    width: 2.1875rem;
    display: block;
    margin: auto;
    max-width: 5rem;
    padding-bottom: 0.5rem;
}

.hide-noom-badge {
    display: none;
}

@media only screen and (min-width: 48rem) {
    .badges-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    }
}

</style>
  